
import Vue, { PropType } from 'vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'SiteFeaturedItemLayout',
  components: { SaIcon },
  props: {
    icon: {
      type: String as PropType<SaIconsType>,
      required: true,
    },
    smallBreak: {
      type: Boolean,
      default: true,
    },
    smallIcon: {
      type: Boolean,
    },
  },
  computed: {
    iconContainerClasses(): string {
      if (this.smallIcon) {
        return this.smallBreak ? 'sm:w-16 sm:h-16' : 'xs:w-12 xs:h-12'
      } else {
        return this.smallBreak ? 'sm:w-24 sm:h-24' : 'xs:w-20 xs:h-20'
      }
    },
    iconSizeClasses(): string {
      if (this.smallIcon) {
        return this.smallBreak ? 'sm:text-3xl' : 'xs:text-2xl'
      } else {
        return this.smallBreak ? 'sm:text-5xl' : 'xs:text-4xl'
      }
    },
    containerClasses(): string {
      if (!this.smallBreak) {
        return this.smallIcon ? 'space-x-3' : 'space-x-5'
      } else {
        let spaceBetween = ''
        if (this.smallIcon) {
          spaceBetween = 'sm:space-x-3'
        } else {
          spaceBetween = 'sm:space-x-5'
        }
        return `${this.smallIcon ? 'space-x-3' : 'flex-col mt-4'} sm:mt-0 sm:flex-row space-y-4 sm:space-y-0 ${spaceBetween}`
      }
    },
  },
})
