
import Vue, { PropType } from 'vue'
import { UserComment } from '~/models/usercomment'
import SiteUserProfileIcon from '~/components/site/user/ProfileIcon.vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'SiteUserCommentProfileIcon',
  components: { SaIcon, SiteUserProfileIcon },
  props: {
    userComment: {
      type: Object as PropType<UserComment>,
      default: undefined,
    },
  },
})
