
import Vue, { PropType } from 'vue'
import { UserComment } from '~/models/usercomment'
import { getListWithPlaceholders } from '~/assets/ts/utils/lists'
import ScrollList from '~/components/_general/ScrollList.vue'
import SiteUserCommentElement from '~/components/site/user/CommentElement.vue'

export default Vue.extend({
  name: 'SiteUserCommentList',
  components: { SiteUserCommentElement, ScrollList },
  props: {
    userComments: {
      type: Array as PropType<UserComment[]>,
      default: () => {
        return []
      },
    },
    placeholderCount: {
      type: Number,
      default: 3,
    },
    endOfList: {
      type: Boolean,
      default: false,
    },
    includeSermon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    comments(): UserComment[] {
      return getListWithPlaceholders(this.userComments, this.placeholderCount)
    },
  },
})
