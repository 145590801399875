
import Vue, { PropType } from 'vue'
import { Sermon } from '~/models/sermon'
import { Media } from '~/models/media'
import { FromSrt, Srt } from '~/assets/ts/utils/srt'
import { secondsToHhMmSs } from '~/assets/ts/utils/date'
import { waitOneFrame } from '~/assets/ts/utils/misc'
import { convertLineBreaks } from '~/assets/ts/utils/strings'
import KeydownEvent from '~/models/generic/KeydownEvent'
import { clamp } from '~/assets/ts/utils/math'
import SiteLoadingIndicator from '~/components/site/LoadingIndicator.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import KeydownObserver from '~/components/_general/KeydownObserver.vue'

export default Vue.extend({
  name: 'PlayerTranscriptElement',
  components: { KeydownObserver, SaIcon, SiteLoadingIndicator },
  props: {
    sermon: {
      type: Object as PropType<Sermon>,
      default: undefined,
    },
    show: {
      type: Boolean,
    },
    caption: {
      type: Object as PropType<Media>,
      default: undefined,
    },
    playerTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      transcript: undefined as any,
      parser: undefined as any | undefined,
      currentSrt: 0,
      search: false,
      searchText: '',
      searchIndex: 0,
      searchSrtIndexes: [] as number[],
      autoScroll: true,
      scrollTimeout: 0,
    }
  },
  computed: {
    jumpToCurrentBuffer(): number {
      return 25
    },
    /** Value shown to the user as the selected search item */
    displaySearchIndex(): number {
      return this.searchIndex + 1
    },
    /** Srt index of the current search item */
    searchSrtIndex(): number {
      return this.searchSrtIndexes[this.searchIndex]
    },
    captionUrl(): string | undefined {
      return this.caption?.downloadURL
    },
    srtArray(): Srt[] {
      if (!this.transcript) return []
      return FromSrt(this.transcript)
    },
    searchMatches(): number {
      return this.searchSrtIndexes.length
    },
    searchInput(): HTMLInputElement | undefined {
      return this.$refs.search as HTMLInputElement
    },
    scrollBody(): HTMLElement | undefined {
      return this.$refs.body as HTMLElement
    },
  },
  watch: {
    show() {
      this.getCaptions()
    },
    searchText() {
      this.getSearchMatches()
    },
    language() {
      this.transcript = undefined
      if (!this.show) return
      this.getCaptions()
    },
    playerTime() {
      if (!this.srtArray) return
      const t = this.playerTime
      const i = this.srtArray.findIndex((srt) => {
        return srt.startTime <= t && srt.endTime >= t
      })
      if (i !== -1) {
        this.currentSrt = i
      }
    },
    currentSrt() {
      this.scrollToCurrent()
      this.scrolled()
    },
  },
  async mounted() {
    this.addScrollListener()
    if (this.srtArray.length) {
      await waitOneFrame()
      this.scrollToCurrent()
    } else {
      await this.getCaptions()
    }
  },
  destroyed() {
    this.removeScrollListener()
  },
  methods: {
    disableSearch() {
      this.searchText = ''
      this.search = false
    },
    keydown(key: KeydownEvent) {
      if (key.Escape) {
        this.disableSearch()
      } else if (key.Enter) {
        this.searchNext()
      } else if (key.ShiftEnter) {
        this.searchPrev()
      } else if (key.Slash && !this.search) {
        key.event.preventDefault()
        this.startSearch()
      }
    },
    getSrtElement(index: number): HTMLButtonElement | undefined {
      if (!this.srtArray.length) return undefined
      const array = this.$refs[`srt-${index}`] as HTMLButtonElement[]
      return array.length ? array[0] : undefined
    },
    addScrollListener() {
      this.scrollBody?.addEventListener('scroll', this.scrolled)
    },
    removeScrollListener() {
      this.scrollBody?.removeEventListener('scroll', this.scrolled)
    },
    scrolled() {
      this.autoScroll = this.currentIsInView()
    },
    enableAutoScroll() {
      this.autoScroll = true
      this.scrollToCurrent()
    },
    getIndexScrollPosition(index: number): number | undefined {
      if (!this.scrollBody) return undefined
      const el = this.getSrtElement(index)
      if (!el) return undefined
      const height = this.scrollBody.clientHeight
      const offset = height / 1.75
      const max = this.scrollBody.scrollHeight - height
      return clamp(el.offsetTop - offset, 0, max)
    },
    currentIsInView(): boolean {
      const scrollPos = this.getIndexScrollPosition(this.currentSrt)
      if (!this.scrollBody || scrollPos === undefined) return true
      const scrollTop = this.scrollBody.scrollTop
      const buffer = this.jumpToCurrentBuffer
      return scrollPos >= scrollTop - buffer && scrollPos <= scrollTop + buffer
    },
    scrollToIndex(index: number) {
      if (!this.scrollBody) return
      const scrollPos = this.getIndexScrollPosition(index)
      if (scrollPos === undefined) return
      this.scrollBody.scrollTop = scrollPos
    },
    scrollToCurrent() {
      if (!this.autoScroll) return
      this.scrollToIndex(this.currentSrt)
    },
    scrollToSearch() {
      this.scrollToIndex(this.searchSrtIndex)
    },
    async startSearch() {
      this.searchIndex = 0
      this.search = true
      await waitOneFrame()
      this.searchText = ''
      this.searchInput?.focus()
    },
    getSearchMatches() {
      this.searchSrtIndexes = []
      if (!this.searchText) return
      const searchText = this.searchText.toLowerCase()
      this.srtArray.forEach((_srt, index) => {
        if (this.srtMatchesSearch(searchText, index)) {
          this.searchSrtIndexes.push(index)
        }
      })
      if (this.searchMatches < 1) return
      if (this.searchSrtIndexes.includes(this.searchIndex)) return
      this.searchIndex = 0
      this.scrollToSearch()
    },
    isMatchedSearch(index: number) {
      return this.searchSrtIndexes.includes(index)
    },
    // feels like this could be a lot more efficient...
    srtMatchesSearch(searchText: string, index: number): boolean {
      const current = convertLineBreaks(
        this.srtArray[index].text.toLowerCase(),
        ' '
      )
      if (current.includes(searchText)) return true

      const next = convertLineBreaks(
        (this.srtArray[index + 1]?.text ?? ' ').toLowerCase(),
        ''
      )
      if (next.includes(searchText)) return false
      return (
        `${current} ${next}`.includes(searchText) ||
        `${current}${next}`.includes(searchText)
      )
    },
    time(seconds: number): string {
      return secondsToHhMmSs(Math.floor(seconds), true)
    },
    searchPrev() {
      if (this.searchIndex === 0) {
        this.searchIndex = this.searchMatches - 1
      } else {
        this.searchIndex -= 1
      }
      this.scrollToSearch()
    },
    searchNext() {
      if (this.searchIndex + 1 === this.searchMatches) {
        this.searchIndex = 0
      } else {
        this.searchIndex += 1
      }
      this.scrollToSearch()
    },
    toggle() {
      const show = !this.show
      this.$emit('show', show)
      if (!show) return
      this.getCaptions()
    },
    async getCaptions() {
      if (!this.show) return
      if (!this.captionUrl) return
      if (this.transcript) return
      await this.$axios.get(this.captionUrl).then(async ({ data }) => {
        this.transcript = data
        await waitOneFrame()
        this.scrollToCurrent()
      })
    },
    goTo(srt: Srt) {
      this.$emit('setTime', srt.startTime)
      this.enableAutoScroll()
    },
  },
})
