
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { SaIconsType } from '~/assets/ts/types/icons'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SiteSeeAll from '~/components/site/SeeAll.vue'

export const SiteTitledSectionProps = {
  title: {
    type: String as PropType<TranslateResult>,
    required: true,
  },
  description: {
    type: String as PropType<TranslateResult>,
    default: '',
  },
  icon: {
    type: String as PropType<SaIconsType>,
    default: undefined,
  },
  seeAll: {
    type: Boolean,
  },
  seeAllBottom: {
    type: Boolean,
  },
  seeAllText: {
    type: String as PropType<TranslateResult | string>,
    default: undefined,
  },
  href: {
    type: String,
    default: undefined,
  },
  to: {
    type: String,
    default: undefined,
  },
  addMargin: {
    type: Boolean,
    default: true,
  },
  enlargeText: {
    type: Boolean,
  },
  hideHeader: {
    type: Boolean,
  },
  subtitle: {
    type: String as PropType<TranslateResult>,
    default: undefined,
  },
  numbered: {
    type: Number,
    default: 0,
  },
  newTab: {
    type: Boolean,
  },
}

export default Vue.extend({
  name: 'SiteTitledSection',
  components: { SiteSeeAll, InlineIcon },
  inheritAttrs: false,
  props: SiteTitledSectionProps,
  computed: {
    showSeeAll(): boolean {
      return this.seeAll || !!this.to || !!this.href
    },
  },
})
