
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'SiteGlobalIndicator',
  components: { SaIcon },
  props: {
    isGlobal: {
      type: Boolean,
    },
    oneLine: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    indicatorClasses(): string {
      return this.isGlobal
        ? `flex space-x-2 sm:-ml-5 ${this.oneLine ? 'items-center' : ''}`
        : ''
    },
  },
})
