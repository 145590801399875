
import Vue from 'vue'
import SiteNavGroupSubnav from '~/components/site/nav/group/Subnav.vue'
import SiteNavLi from '~/components/site/nav/Li.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import { modifyQs, qsValue } from '~/assets/ts/utils/params'
import { displayUrl, waitOneFrame } from '~/assets/ts/utils/misc'

export default Vue.extend({
  name: 'SiteNavGroupAdmin',
  components: {
    HorizontalRule,
    SiteNavLi,
    SiteNavGroupSubnav,
  },
  data() {
    return {
      api: qsValue(this, 'apibranch') ?? '',
    }
  },
  computed: {
    currentApi(): string {
      return displayUrl(this.$apiClient.baseURL.replace('/v2', ''))
    },
  },
  methods: {
    navigate() {
      this.$emit('navigate')
    },
    async paste() {
      await waitOneFrame()
      const match = this.api.match(
        /api-([a-zA-Z0-9_-]+)\.review\.sermonaudio\.com/
      )
      if (!match) return
      this.api = match[1]
    },
    applyApi(value: string) {
      modifyQs(this, {
        apibranch: value ? encodeURI(value) : undefined,
      })
      location.reload()
    },
  },
})
