
import Vue, { PropType } from 'vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'SiteNavGroupHeader',
  components: { SaIcon },
  props: {
    icon: {
      type: String as PropType<SaIconsType>,
      default: undefined,
    },
  },
})
