import Vue from 'vue'

export type LanguageCode2 = `${string}${string}`

export function getLanguageName(vue: Vue, code: LanguageCode2) {
  if (typeof Intl.DisplayNames === 'function') {
    const displayNames = new Intl.DisplayNames([vue.$i18n.locale], {
      type: 'language',
    })
    return displayNames.of(code) ?? (code as string)
  }
  return code as string
}
