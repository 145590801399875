
import Vue, { PropType } from 'vue'
import { Broadcaster } from '~/models/broadcaster'
import Poller from '~/components/_general/Poller.vue'
import { LivePollerSiteFrequency } from '~/assets/ts/utils/webcasts'

export default Vue.extend({
  name: 'LivePoller',
  components: { Poller },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
    active: {
      type: Boolean,
      default: true,
    },
    pollOnActivate: {
      type: Boolean,
    },
    countOnly: {
      type: Boolean,
    },
    frequencySeconds: {
      type: Number,
      default: LivePollerSiteFrequency,
    },
  },
  data() {
    return {
      broadcasterLivePoll: undefined as boolean | undefined,
    }
  },
  computed: {
    broadcasterLive(): boolean {
      if (!this.broadcaster) return false
      if (this.broadcasterLivePoll !== undefined)
        return this.broadcasterLivePoll
      return this.broadcaster.webcastInProgress
    },
    updatedTimestamp(): number {
      if (this.countOnly) {
        return this.$store.getters['webcasts/webcastCountUpdateTimestamp']
      }
      return this.$store.getters['webcasts/webcastsUpdateTimestamp']
    },
    threshold(): number {
      return Math.max(this.frequencySeconds * 0.5, 20) * 1000
    },
    cacheTTL(): number {
      return Math.min(this.frequencySeconds, 60)
    },
    dispatchType(): string {
      return this.countOnly
        ? 'webcasts/fetchWebcastCount'
        : 'webcasts/fetchWebcastsInProgress'
    },
  },
  methods: {
    async updateLiveStatus() {
      if (this.broadcaster) {
        await this.updateBroadcasterLiveStatus()
      } else {
        // if webcasts in progress has been updated somewhere else recently, don't re-poll
        const timeSince = new Date().getTime() - this.updatedTimestamp
        // making sure the time between polls don't get _too_ far apart
        if (timeSince >= this.threshold) {
          await this.$store.dispatch(this.dispatchType)
        }
      }
    },
    async updateBroadcasterLiveStatus() {
      const broadcasterID = this.broadcaster?.id
      await this.$store
        .dispatch(this.dispatchType, {
          broadcasterID,
          cacheTTL: this.cacheTTL,
        })
        .catch((err) => {
          console.error(err)
        })
        .then(() => {
          const live =
            this.$store.getters['webcasts/broadcasterLive'](broadcasterID)
          this.broadcasterLivePoll = live
          this.$emit('poll', live)
        })
    },
  },
})
