
import Vue, { PropType } from 'vue'
import { HashtagApi } from '~/apiclient/apihashtags'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import SaLink from '~/components/_general/SaLink.vue'
import { HashtagUrl } from '~/assets/ts/utils/urls'

export default Vue.extend({
  name: 'HashtagElement',
  components: { LoadingElement },
  props: {
    hashtag: {
      type: undefined as PropType<HashtagApi> | undefined,
      default: undefined,
    },
  },
  computed: {
    SaLink() {
      return SaLink
    },
    hashtagName(): string {
      if (!this.hashtag) return ''
      return this.hashtag.hashtag
    },
    hashtagCount(): number | undefined {
      if (!this.hashtag) return undefined
      return this.hashtag.count
    },
    hashtagCountDisplay(): string {
      if (!this.hashtag) return ''
      return this.hashtag.count.toLocaleString()
    },
    hashtagUrl(): string {
      return HashtagUrl(this.hashtagName)
    },
  },
})
