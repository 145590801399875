
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import SiteButton from '~/components/site/Button.vue'
import { toCurrency } from '~/assets/ts/utils/math'
import SaLink from '~/components/_general/SaLink.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import { AdType } from '~/apiclient/apiads'
import { AdPage, promoteSermonUrl, promotionUrl } from '~/assets/ts/utils/urls'
import { GetAdCreateText, GetAdTypeTitle } from '~/assets/ts/utils/promotions'

export default Vue.extend({
  name: 'SitePromotionalInfoBox',
  components: { LoadingElement, SaLink, SiteButton },
  props: {
    adType: {
      type: String as PropType<AdType>,
      required: true,
    },
    cost: {
      type: String,
      default: '',
    },
    sermonID: {
      type: String,
      default: '',
    },
  },
  computed: {
    title(): TranslateResult {
      if (this.sermonID && this.adType === AdType.Text) {
        return this.$t('Sermon Text Ad')
      }
      return GetAdTypeTitle(this, this.adType, !this.sermonID)
    },
    price(): string | undefined {
      if (!this.cost) return undefined
      return toCurrency(parseInt(this.cost), this.$i18n.locale)
    },
    createText(): TranslateResult {
      return GetAdCreateText(this, this.adType)
    },
    createLink(): string {
      if (this.sermonID) return promoteSermonUrl(this.adType, this.sermonID)
      return promotionUrl(this.adType, AdPage.Create)
    },
    historyLink(): string {
      return promotionUrl(this.adType, AdPage.Orders)
    },
    statsLink(): string {
      return promotionUrl(this.adType, AdPage.Stats)
    },
    exampleImage(): string {
      switch (this.adType) {
        case AdType.FeaturedSermon:
          return require('~/assets/svgs/promo/devicethumb-mainfeature.svg')
        case AdType.Graphic:
          return require('~/assets/svgs/promo/devicethumb-sponsor.svg')
        case AdType.Text:
        default:
          return require('~/assets/svgs/promo/devicethumb-minitextad.svg')
      }
    },
    description(): TranslateResult {
      switch (this.adType) {
        case AdType.FeaturedSermon:
          return this.$t(
            'Featuring a sermon will display it prominently on the home page as well as at the top of the Promoted Content section in the side column on most pages of the site. Your sermons will also be featured on the Sermons 2.0 app for iOS and Android, as well as Smart TV app.'
          )
        case AdType.Graphic:
          return this.$t(
            'Graphical ads include an image, title, and short description. These ads are displayed on most pages of the website and mobile apps. You can use these ads to promote an external website with greater visibility.'
          )
        case AdType.Text:
        default:
          if (this.sermonID) {
            return this.$t(
              'Text-based ads are displayed on most pages of the website and mobile apps. You can use these ads to promote a sermon.'
            )
          }
          return this.$t(
            'Text-based ads are displayed on most pages of the website and mobile apps. You can use these ads to promote a sermon or an external website of your choice along with a title and short description.'
          )
      }
    },
  },
})
