
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { pojoToSermon, Sermon } from '~/models/sermon'
import {
  AutoplayNextState,
  BroadcasterDisplay,
  DateFormat,
  PlayerMediaType,
  PlayerStatus,
  SeriesDisplay,
  SermonDisplay,
  SermonMetadataExtendedInfo,
  SpeakerDisplay,
} from '~/assets/ts/enums'
import { HeadOptions, images, metadata } from '~/assets/ts/utils/metadata'
import { UserComment } from '~/models/usercomment'
import {
  siteBroadcasterUrl,
  siteSeriesUrl,
  siteSermonUrl,
  siteSpeakerUrl,
} from '~/assets/ts/utils/urls'
import { SermonApi, SermonRequestOptions } from '~/apiclient/apisermons'
import { Series } from '~/models/series'
import { Speaker } from '~/models/speaker'
import { Broadcaster } from '~/models/broadcaster'
import KeydownEvent from '~/models/generic/KeydownEvent'
import { LanguageCode3, Media, MediaType } from '~/models/media'
import { getSermonsHelper } from '~/assets/ts/utils/sermons'
import { clamp } from '~/assets/ts/utils/math'
import { getListWithPlaceholders } from '~/assets/ts/utils/lists'
import { localizeDateTime, timesince } from '~/assets/ts/utils/date'
import { HashtagApi } from '~/apiclient/apihashtags'
import { qsBool } from '~/assets/ts/utils/params'
import { DropdownOption } from '~/components/_general/Dropdown.vue'
import SiteMediaTypeSwitcher from '~/components/site/MediaTypeSwitcher.vue'
import PlayerWebcastNotification from '~/components/player/WebcastNotification.vue'
import PlayerElement from '~/components/player/Element.vue'
import PlayerTranscriptElement from '~/components/player/TranscriptElement.vue'
import KeydownObserver from '~/components/_general/KeydownObserver.vue'
import SiteLayoutWithPromo from '~/components/site/layout/WithPromo.vue'
import CustomDropdown from '~/components/_general/CustomDropdown.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SiteGlobalIndicator from '~/components/site/GlobalIndicator.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import SaLink from '~/components/_general/SaLink.vue'
import FollowButton from '~/components/_general/FollowButton.vue'
import SiteButton from '~/components/site/Button.vue'
import MediaDownloadDropdown from '~/components/_general/MediaDownloadDropdown.vue'
import SpeakerElement from '~/components/_general/SpeakerElement.vue'
import BroadcasterElement from '~/components/_general/BroadcasterElement.vue'
import ShowMore from '~/components/_general/ShowMore.vue'
import MarkdownElement from '~/components/markdown/Element.vue'
import SiteDataTable from '~/components/site/DataTable.vue'
import HashtagElement from '~/components/_general/HashtagElement.vue'
import SiteTitledSection from '~/components/site/TitledSection.vue'
import SiteDocumentElement from '~/components/site/DocumentElement.vue'
import SiteSermonList from '~/components/site/SermonList.vue'
import NavigationTab from '~/components/_general/NavigationTab.vue'
import SiteSeeAll from '~/components/site/SeeAll.vue'
import SiteUserAddComment from '~/components/site/user/AddComment.vue'
import SiteUserCommentList from '~/components/site/user/CommentList.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import ShareButton from '~/components/_general/ShareButton.vue'
import SitePromotionalSermonModal from '~/components/site/promotional/SermonModal.vue'
import SiteSeriesMoreIn from '~/components/site/series/MoreIn.vue'
import { SiteAlertModal, SiteUserRequiredModal } from '~/assets/ts/utils/site'
import { AlertButton } from '~/models/generic/alert-button'
import Accordion from '~/components/_general/Accordion.vue'
import LivePoller from '~/components/_general/LivePoller.vue'
import SiteManageButtons from '~/components/site/ManageButtons.vue'
import { logError } from '~/assets/ts/utils/misc'
import { getLanguageName, LanguageCode2 } from '~/assets/ts/utils/i18n'
import { LivePollerSermonFrequency } from '~/assets/ts/utils/webcasts'

export default Vue.extend({
  components: {
    SiteManageButtons,
    LivePoller,
    Accordion,
    SiteSeriesMoreIn,
    HorizontalRule,
    SiteUserCommentList,
    SiteUserAddComment,
    SiteSeeAll,
    NavigationTab,
    SiteSermonList,
    SiteDocumentElement,
    SiteTitledSection,
    HashtagElement,
    SiteDataTable,
    MarkdownElement,
    ShowMore,
    BroadcasterElement,
    SpeakerElement,
    MediaDownloadDropdown,
    SiteButton,
    FollowButton,
    SaLink,
    LoadingElement,
    SiteGlobalIndicator,
    InlineIcon,
    CustomDropdown,
    SiteLayoutWithPromo,
    KeydownObserver,
    PlayerTranscriptElement,
    PlayerElement,
    PlayerWebcastNotification,
    SiteMediaTypeSwitcher,
  },
  layout: 'site',
  middleware: ['fetchSermon'],
  data() {
    const showTranscript = qsBool(this, 'transcript')
    return {
      showTranscript,
      hasShownTranscript: showTranscript,
      localMediaType: undefined as PlayerMediaType | undefined,
      moreFromBroadcaster: [] as Sermon[],
      moreFromSpeaker: [] as Sermon[],
      sermonComments: [] as UserComment[],
      startTime: 0,
      status: PlayerStatus.Static as PlayerStatus,
      commentPage: 1,
      mounted: false,
      // TODO: make these start correctly in case speaker but no broadcaster
      broadcasterActive: true,
      speakerActive: false,
      sermonID: this.$route.params.sermonID as string,
      autoplayNextSermonPojo: undefined as SermonApi | undefined,
      autoplayPreviousSermonPojo: undefined as SermonApi | undefined,
    }
  },
  async fetch() {
    await this.getStartTime()
  },
  head(this: any) {
    const options = {} as HeadOptions
    const sermon = this.sermon as Sermon | undefined
    if (sermon) {
      options.title = this.sermonTitle
      options.description = `${sermon.speaker.displayName} | ${sermon.broadcaster.displayName}`

      let url = 'https://media.sermonaudio.com/images/web/images/og-sermon.jpg'
      const thumbnail = sermon.thumbnailURL(1920)
      if (sermon.hasVideo && thumbnail) {
        url = thumbnail
      } else if (sermon.eventType === 'Radio Broadcast') {
        url = 'https://media.sermonaudio.com/images/web/images/og-broadcast.jpg'
      } else if (sermon.eventType === 'Podcast') {
        url = 'https://media.sermonaudio.com/images/web/images/og-podcast.jpg'
      }
      options.images = images(url, true)
    }
    return metadata(this, options)
  },
  computed: {
    livePollFrequency(): number {
      return LivePollerSermonFrequency
    },
    showGlobalize(): boolean {
      return qsBool(this, 'debug')
    },
    moreSermonsCount(): number {
      return 4
    },
    SpeakerDisplay() {
      return SpeakerDisplay
    },
    BroadcasterDisplay() {
      return BroadcasterDisplay
    },
    SeriesDisplay() {
      return SeriesDisplay
    },
    SermonMetadataExtendedInfo() {
      return SermonMetadataExtendedInfo
    },
    PlayerMediaType() {
      return PlayerMediaType
    },
    SermonDisplay() {
      return SermonDisplay
    },
    ShareButton() {
      return ShareButton
    },
    displayTranscript(): boolean {
      return this.showTranscript && this.hasTranscript
    },
    transcript(): Media | undefined {
      if (!this.sermon) return undefined
      return this.sermon.media?.getTranscript(this.languageCode3)
    },
    hasTranscript(): boolean {
      return !!this.transcript
    },
    broadcasterIsLive(): boolean {
      return this.$store.getters['webcasts/broadcasterLive'](this.broadcasterID)
    },
    duration(): string | undefined {
      if (!this.sermon) return undefined
      return this.sermon.durationString
    },
    hashtags(): HashtagApi[] {
      if (!this.sermon) return []
      return this.sermon.hashtags
    },
    playerClasses(): string {
      return this.video
        ? 'w-[115vh] max-w-full'
        : `px-site w-full pb-8 ${this.popout ? 'pt-10' : 'pt-20'}`
    },
    playing(): boolean {
      return this.status === PlayerStatus.Playing
    },
    favorite(): boolean {
      return !!this.$store.getters['users/followed']({
        id: this.sermonID,
        type: 'sermon',
      })
    },
    broadcasterUrl(): string {
      return siteBroadcasterUrl(this.sermon?.broadcaster, 'sermons')
    },
    speakerUrl(): string {
      return siteSpeakerUrl(this.sermon?.speaker)
    },
    hasVideo(): boolean {
      return !!this.sermon?.hasVideo
    },
    hasAudio(): boolean {
      return !!this.sermon?.hasAudio
    },
    featureDate(): string | undefined {
      if (!this.sermon || !this.sermon.featureDate) return undefined
      return localizeDateTime(this.sermon.featureDate, DateFormat.ShortDate)
    },
    preachDate(): string | undefined {
      if (!this.sermon || !this.sermon.preachDate) return undefined
      return localizeDateTime(this.sermon.preachDate, DateFormat.ShortDate)
    },
    publishDate(): string | undefined {
      if (!this.sermon || !this.sermon.publishDate) return undefined
      return localizeDateTime(this.sermon.publishDate, DateFormat.ShortDate)
    },
    mediaType(): PlayerMediaType {
      if (this.localMediaType !== undefined) return this.localMediaType
      const noVideo = this.sermon && !this.sermon.hasVideo
      const audioUrl = this.$route.params.mediaType === 'a'
      return noVideo || (audioUrl && this.hasAudio)
        ? PlayerMediaType.Audio
        : PlayerMediaType.Video
    },
    video(): boolean {
      return this.mediaType === PlayerMediaType.Video
    },
    broadcasterID(): string | undefined {
      return this.sermon?.broadcaster.id
    },
    speaker(): Speaker | undefined {
      return this.sermon?.speaker
    },
    series(): Series | undefined {
      return this.sermon?.series
    },
    autoplayNextTitle(): string | undefined {
      return this.series?.title
    },
    autoplayNextPojo(): SermonApi | undefined {
      const state = this.$store.getters[
        'player/autoplayNextState'
      ] as AutoplayNextState
      switch (state) {
        case AutoplayNextState.Forward:
          return this.autoplayNextSermonPojo
        case AutoplayNextState.Reverse:
          return this.autoplayPreviousSermonPojo
        case AutoplayNextState.Off:
        default:
          return undefined
      }
    },
    autoplayNextSermon(): Sermon | undefined {
      const autoplayStatuses = [PlayerStatus.Ended, PlayerStatus.FetchingNext]
      if (!autoplayStatuses.includes(this.status)) return undefined
      return pojoToSermon(this.autoplayNextPojo)
    },
    broadcaster(): Broadcaster | undefined {
      return this.sermon?.broadcaster
    },
    sermon(): Sermon | undefined {
      if (this.sermonPojo) {
        return new Sermon(this.sermonPojo)
      }
      return undefined
    },
    sermonPojo(): Record<string, any> | undefined {
      return this.$store.getters['sermons/sermon'](this.sermonID)
    },
    popout(): boolean {
      return !!this.$route.query.popout
    },
    autoplay(): boolean {
      return qsBool(this, 'autoplay')
    },
    seriesUrl(): string {
      return siteSeriesUrl(this.series)
    },
    sermonUrl(): string {
      return siteSermonUrl(this.sermon)
    },
    // TODO: Make sure to get correct global title
    sermonTitle(): string {
      if (!this.sermon) return ''
      switch (this.languageCode2) {
        // case 'es':
        //   return 'Titulo en Español'
        // case 'de':
        //   return 'Titel auf Deutsch'
        default:
          return this.sermon.fullTitle
      }
    },
    // TODO: Make sure to get correct global description
    moreInfoText(): string | undefined {
      if (!this.sermon) return undefined
      switch (this.languageCode2) {
        // case 'es':
        //   return 'Esta es una descripción larga en español. Está diseñado para mostrar el ejemplo de una descripción de sermón grande que ha sido traducida al español. Esto se usará para los sermones globales y debería mostrar cómo funciona bastante bien. Si tradujiste esto, espero que estés teniendo un gran día. Esto debe ser un poco más largo para que aparezca el botón Mostrar más. Esperemos que este texto sea suficiente y el botón aparezca como lo hemos previsto.'
        // case 'de':
        //   return 'Dies ist eine lange Beschreibung auf Deutsch. Es soll ein Beispiel für eine umfangreiche Predigtbeschreibung zeigen, die ins Spanische übersetzt wurde. Dies wird für globale Predigten verwendet und soll zeigen, wie es ganz gut funktioniert. Wenn Sie dies übersetzt haben, hoffe ich, dass Sie einen tollen Tag haben. Dies muss etwas länger dauern, damit die Schaltfläche „Mehr anzeigen“ angezeigt wird. Hoffentlich reicht dieser Text aus und die Schaltfläche wird wie erwartet angezeigt.'
        default:
          return this.sermon.moreInfoText ?? undefined
      }
    },
    pageLanguage(): string | undefined {
      return this.$route.query.lang as string | undefined
    },
    // TODO: we need a way to handle languages that can't be globalized
    sermonLanguageDisplay(): TranslateResult {
      return getLanguageName(this, this.languageCode2)
    },
    isGlobal(): boolean {
      return this.languageCode2 !== this.sermon?.language
    },
    languageCode2(): LanguageCode2 {
      return this.pageLanguage ?? this.sermon?.language ?? 'en'
    },
    languageCode3(): LanguageCode3 | undefined {
      return this.sermon?.languageCode3
    },
    endOfSermonComments(): boolean {
      if (!this.sermon) return false
      return this.sermonComments.length >= this.sermon.commentCount
    },
    hasDocuments(): boolean {
      if (!this.sermon) return false
      return (
        this.sermon.media.text.length > 0 ||
        this.sermon.media.caption.length > 0
      )
    },
    documents(): Media[] {
      if (!this.sermon) return []
      const docs = this.sermon.media.text.filter(
        (m) => m.type !== MediaType.Word
      )
      if (this.transcript) {
        docs.push(this.transcript)
      }
      return getListWithPlaceholders(docs)
    },
    // TODO: Make sure languages are correct (original language)
    globalSermonMessage(): TranslateResult {
      return this.$t(
        'You are viewing this sermon using an automated translation to {language}. Click to view original Sermon in original language ({originalLanguage})',
        {
          language: this.sermonLanguageDisplay,
          originalLanguage: 'English',
        }
      )
    },
    globalOptions(): DropdownOption[] {
      return [
        {
          value: 'en',
          display: 'English (US)',
        },
        {
          value: 'es',
          display: 'Spanish',
        },
        {
          value: 'de',
          display: 'German',
        },
      ] as DropdownOption[]
    },
    admin(): boolean {
      if (!this.$users.user) return false
      return this.$users.user.admin
    },
    globalizeUrl(): string {
      return `${siteSermonUrl(this.sermon)}/globalize`
    },
    moreFromSeeAllUrl(): string {
      if (this.broadcasterActive) {
        return this.broadcasterUrl
      } else {
        return this.speakerUrl
      }
    },
    player(): any | undefined {
      return this.$refs.player
    },
    lastAudioPlayedString(): TranslateResult {
      if (!this.sermon || !this.sermon.lastAudioAccessDateTime) return ''
      return this.$t('Audio last played {date}', {
        date: timesince(this.sermon.lastAudioAccessDateTime, this),
      })
    },
    lastVideoPlayedString(): TranslateResult {
      if (!this.sermon || !this.sermon.lastVideoAccessDateTime) return ''
      return this.$t('Video last played {date}', {
        date: timesince(this.sermon.lastVideoAccessDateTime, this),
      })
    },
  },
  mounted() {
    this.mounted = true
    this.getLists()
  },
  methods: {
    getLists(speakers = false, broadcasters = false) {
      if (broadcasters) {
        this.getMoreBroadcasterSermons()
      }
      if (speakers) {
        this.getMoreFromSpeaker()
      }
      this.getSermonComments()
    },
    /** Sermon Ended, lets get the next sermon's info */
    async getNewAutoplaySermon() {
      const sermonID = this.autoplayNextSermon?.id
      if (!sermonID) return
      if (this.$store.getters['sermons/sermon'](sermonID)) return
      await this.$store.dispatch('sermons/fetchSermon', {
        sermonID,
        lite: false,
        includeWaveform: true,
      })
    },
    /** Autoplay next triggered - set up our next sermon */
    async setNewAutoplaySermon() {
      const next = this.autoplayNextSermon
      if (!next) return
      await this.getNewAutoplaySermon()
      const newSpeaker = this.speaker?.id !== next.speaker.id
      // One day we may have mixed lists, but for now we don't
      const newBroadcaster = false

      // this officially switches our sermon
      this.sermonID = next.id

      // tell the browser we're on a new sermon
      this.updateUrl(this.mediaType)

      // Reset defaults
      this.startTime = 0
      this.commentPage = 1
      this.moreFromSpeaker = []
      this.sermonComments = []

      this.getLists(newSpeaker, newBroadcaster)
    },
    openPromote() {
      if (!this.$users.user) {
        SiteUserRequiredModal(this, this.$t('Promote Sermon'))
        return
      }
      SiteAlertModal(this, {
        maxWidth: 960,
        title: this.$t('Promote Sermon'),
        component: SitePromotionalSermonModal,
        componentProps: {
          sermonPojo: this.sermonPojo,
        },
        neutral: new AlertButton({
          text: this.$t('Cancel'),
        }),
      })
    },
    toggleTranscript(show: boolean) {
      this.showTranscript = show
      this.hasShownTranscript = show || this.hasShownTranscript
    },
    playerStatusUpdate(status: PlayerStatus) {
      this.status = status
      if (status < PlayerStatus.Ended || !this.autoplayNextSermon) return
      if (status === PlayerStatus.FetchingNext) {
        this.setNewAutoplaySermon()
      } else {
        this.getNewAutoplaySermon()
      }
    },
    async getStartTime() {
      if (!this.$users.loggedIn) return
      const history = await this.$apiClient.getSermonPlayHistory(this.sermonID)
      if (!history.play_position) return
      this.startTime = history.play_position
    },
    setMediaType(type: PlayerMediaType) {
      this.localMediaType = type
      this.updateUrl(type)
    },
    updateUrl(type: PlayerMediaType) {
      const url = `${siteSermonUrl(this.sermon, type)}${window.location.search}`
      history.pushState({}, '', url)
    },
    async getMoreBroadcasterSermons() {
      this.moreFromBroadcaster = await this.getMore({
        broadcasterID: this.broadcasterID,
      })
    },
    async getMoreFromSpeaker() {
      this.moreFromSpeaker = await this.getMore({
        speakerID: this.speaker?.id,
      })
    },
    async getMore(options: Partial<SermonRequestOptions>): Promise<Sermon[]> {
      const list = [] as Sermon[]
      await getSermonsHelper(
        this.$apiClient,
        list,
        { ...options, pageSize: this.moreSermonsCount + 1 },
        this.sermonID
      ).catch(logError)
      list.length = clamp(list.length, 0, this.moreSermonsCount)
      return list
    },
    async getSermonComments() {
      if (!this.sermon?.commentCount || this.endOfSermonComments) return
      const sermonComments = await this.$apiClient.getSermonComments(
        this.sermonID,
        this.commentPage
      )
      sermonComments.results.forEach((comment) => {
        this.sermonComments.push(new UserComment(comment))
      })
      this.commentPage += 1
    },
    keydown(key: KeydownEvent) {
      if (key.A && this.video) {
        this.setMediaType(PlayerMediaType.Audio)
      } else if (key.V && !this.video) {
        this.setMediaType(PlayerMediaType.Video)
      }
    },
    pause() {
      if (!this.player) return
      this.player.pause()
    },
    selectGlobalLanguage(value: DropdownOption) {
      const dropdown = this.$refs.globalDropdown as Vue
      if (dropdown) {
        dropdown.$emit('close')
      }
      // TODO: nav/append with query string
      this.$navigateTo({
        path: this.$route.path,
        query: { lang: value.value as string },
      })
    },
    goToTime(seconds: number) {
      if (!this.player) return
      this.player.setTime(seconds)
      if (!this.player.playing) {
        this.player.play()
      }
    },
  },
})
