import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { BibleBookApi } from '~/apiclient/apibible'
import { EventTypeApi, LanguageApi } from '~/apiclient/apifilters'
import { GroupApi } from '~/apiclient/apigroups'

export const state = () => ({
  bible: [] as BibleBookApi[],
  events: [] as EventTypeApi[],
  languages: [] as LanguageApi[],
  groups: [] as GroupApi[],
})

export type FiltersState = ReturnType<typeof state>

export const mutations: MutationTree<FiltersState> = {
  SET_BIBLE: (state, data: BibleBookApi[]) => {
    state.bible = data
  },
  SET_EVENTS: (state, data: EventTypeApi[]) => {
    state.events = data
  },
  SET_LANGUAGES: (state, data: LanguageApi[]) => {
    state.languages = data
  },
  SET_GROUPS: (state, data: GroupApi[]) => {
    state.groups = data
  },
  RESET: (state) => {
    // Currently groups will never vary, so we never need to reset them
    // state.groups = []
    state.events = []
    state.languages = []
    state.bible = []
  },
}

export const getters: GetterTree<FiltersState, FiltersState> = {
  bible: (state) => state.bible,
  events: (state) => state.events,
  languages: (state) => state.languages,
  groups: (state) => state.groups,
}

export const actions: ActionTree<FiltersState, FiltersState> = {
  async getBible({ commit, state }) {
    if (state.bible.length) return
    const { results } = await this.$apiClient.getBibleStructure()
    commit('SET_BIBLE', results)
  },
  async getEvents({ commit, state }) {
    if (state.events.length) return
    const { results } = await this.$apiClient.getSermonEventTypes()
    commit('SET_EVENTS', results)
  },
  async getLanguages({ commit, state }) {
    if (state.languages.length) return
    const languages = await this.$apiClient.getLanguages()
    commit('SET_LANGUAGES', languages)
  },
  async getGroups({ commit, state }) {
    if (state.groups.length) return
    const results = await this.$apiClient.getGroups()
    commit('SET_GROUPS', results)
  },
  // async getSaStats({ commit }) {
  //   try {
  //     const { data } = await this.$axios.get('/node/discover/duration')
  //     commit('SET_DISCOVER_DURATIONS', data as DiscoverDurationType)
  //   } catch (e) {
  //     logError(e)
  //   }
  // },
}
