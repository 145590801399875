
import Vue, { PropType } from 'vue'
import { SermonApi } from '~/apiclient/apisermons'
import { Sermon } from '~/models/sermon'
import SermonElement from '~/components/_general/SermonElement.vue'
import SitePromotionalInfoBox from '~/components/site/promotional/InfoBox.vue'
import { AdType } from '~/apiclient/apiads'
import { GetAdInfo, GetAdTypeApi } from '~/assets/ts/utils/promotions'
import { SermonDisplay } from '~/assets/ts/enums'
import { PromiseAll } from '~/assets/ts/utils/misc'

export default Vue.extend({
  name: 'SitePromotionalSermonModal',
  components: { SitePromotionalInfoBox, SermonElement },
  props: {
    sermonPojo: {
      type: Object as PropType<SermonApi>,
      required: true,
    },
  },
  data() {
    return {
      textCost: undefined as string | undefined,
      featureCost: undefined as string | undefined,
    }
  },
  computed: {
    SermonDisplay() {
      return SermonDisplay
    },
    AdType() {
      return AdType
    },
    sermon(): Sermon {
      return new Sermon(this.sermonPojo)
    },
    sermonID(): string {
      return this.sermon.id
    },
    canFeature(): boolean {
      return this.featureCost !== undefined
    },
    canText(): boolean {
      return this.textCost !== undefined
    },
  },
  async mounted() {
    await PromiseAll([this.checkFeatured(), this.checkText()])
  },
  methods: {
    async getCost(adType: AdType): Promise<string | undefined> {
      try {
        const data = await this.$apiClient.getAdConfig({
          sermonID: this.sermonID,
          adType: GetAdTypeApi(adType),
        })
        return GetAdInfo(data, adType)?.cost
      } catch {
        return undefined
      }
    },
    async checkFeatured() {
      this.featureCost = await this.getCost(AdType.FeaturedSermon)
    },
    async checkText() {
      this.textCost = await this.getCost(AdType.Text)
    },
  },
})
