
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { Media, MediaType } from '~/models/media'
import { humanFileSize } from '~/assets/ts/utils/math'
import { SaIconsType } from '~/assets/ts/types/icons'
import SaIcon from '~/components/_general/SaIcon.vue'

interface Document {
  name: TranslateResult
  type: TranslateResult
  icon: SaIconsType
}

export default Vue.extend({
  name: 'SiteDocumentElement',
  components: { SaIcon },
  props: {
    document: {
      type: Object as PropType<Media>,
      required: true,
    },
  },
  computed: {
    documentUrl(): string | undefined {
      if (this.caption) return undefined
      return this.document.streamURL || this.document.downloadURL
    },
    fileSize(): string {
      if (!this.document.fileSizeBytes) return ''
      return humanFileSize(this.document.fileSizeBytes)
    },
    auto(): boolean {
      return !!this.document?.autoGenerated
    },
    caption(): boolean {
      return this.document?.type === MediaType.Caption
    },
    documentInfo(): Document {
      const defaultDoc = {
        name: this.$t('Premium Transcript'),
        type: this.$t('Transcript'),
        icon: 'file-alt',
      } as Document

      if (!this.document) return defaultDoc
      switch (this.document.type) {
        case MediaType.PDF:
          return {
            name: 'PDF',
            type: 'PDF',
            icon: 'file-pdf',
          }
        case MediaType.Word:
          return {
            name: this.$t('Premium Transcript'),
            type: this.$t('Word Document') + ' (DOC)',
            icon: 'file-word',
          }
        case MediaType.Caption:
          return {
            name: `${this.$t('Raw Transcript')}${
              this.auto ? ` (${this.$t('Auto')})` : ''
            }`,
            type: this.$t('Plain Text') + ' (TXT)',
            icon: 'file-alt',
          }
        default:
          return defaultDoc
      }
    },
  },
  methods: {
    checkUser(event: Event) {
      if (this.caption) {
        event.preventDefault()
        this.document.downloadTranscript(this)
      }
    },
  },
})
