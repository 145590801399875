
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SaLink from '~/components/_general/SaLink.vue'

export default Vue.extend({
  name: 'SiteSeeAll',
  components: { InlineIcon },
  props: {
    seeAllText: {
      type: String as PropType<TranslateResult | string>,
      default() {
        return this.$t('See All')
      },
    },
    href: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    newTab: {
      type: Boolean,
    },
  },
  computed: {
    componentType(): any {
      if (this.to) {
        return SaLink
      } else if (this.href) {
        return 'a'
      } else {
        return 'button'
      }
    },
  },
})
