
import Vue, { PropType } from 'vue'
import { Sermon } from '~/models/sermon'
import VideoThumbnail from '~/components/_general/VideoThumbnail.vue'
import SaImage from '~/components/_general/SaImage.vue'
import SaLink from '~/components/_general/SaLink.vue'
import SpeakerImage from '~/components/_general/SpeakerImage.vue'

export default Vue.extend({
  name: 'SermonThumbnail',
  components: { SpeakerImage, SaLink, SaImage, VideoThumbnail },
  props: {
    sermon: {
      type: Object as PropType<Sermon>,
      default: undefined,
    },
    hoverPreview: {
      type: Boolean,
      default: true,
    },
    preview: {
      type: Boolean,
    },
    previewControls: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
    sermonUrl: {
      type: String,
      default: '',
    },
    loadingClasses: {
      type: String,
      default: '',
    },
    absoluteUrls: {
      type: Boolean,
    },
    mobilePreview: {
      type: Boolean,
    },
  },
  computed: {
    smallThumbnail(): string {
      return this.sermon ? this.sermon.thumbnailURL(96) : ''
    },
    fullThumbnail(): string {
      return this.sermon ? this.sermon.thumbnailURL(360) : ''
    },
    thumbnail(): string {
      return this.small ? this.smallThumbnail : this.fullThumbnail
    },
    duration(): string | undefined {
      return this.sermon?.durationString
    },
    mobile() {
      if (this.mobilePreview) return false
      return this.$device.isMobile
    },
  },
})
