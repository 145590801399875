
/* eslint-disable import/no-named-as-default-member */
// This eslint-disable is added due to Events and ErrorTypes from Hls currently not being available as enums, but only as types
// https://github.com/video-dev/hls.js/issues/5630
import Vue, { PropType } from 'vue'
import Hls, { CuesParsedData, NonNativeTextTracksData } from 'hls.js'
import { TranslateResult } from 'vue-i18n'
import {
  AutoplayNextState,
  PlayerCastPlatform,
  PlayerMediaType,
  PlayerResumeState,
  PlayerStatus,
  SermonMetadataDisplay,
} from '~/assets/ts/enums'
import { Sermon } from '~/models/sermon'
import { Subtitle, SubtitleTrack } from '~/models/player/subtitle'
import { Quality } from '~/models/player/quality'
import { Broadcaster } from '~/models/broadcaster'
import { HSLA } from '~/assets/ts/utils/color'
import { clamp } from '~/assets/ts/utils/math'
import { Webcast } from '~/models/webcast'
import { qsBool } from '~/assets/ts/utils/params'
import { waitOneFrame } from '~/assets/ts/utils/misc'
import PlayerShortcutModal from '~/components/player/ShortcutModal.vue'
import PlayerAutoplayNextContainer from '~/components/player/AutoplayNextContainer.vue'
import PlayerAutoResumeContainer from '~/components/player/AutoResumeContainer.vue'
import PlayerEmbedDetails from '~/components/player/EmbedDetails.vue'
import PlayerWebcastNotification from '~/components/player/WebcastNotification.vue'
import PlayerMetadata from '~/components/player/Metadata.vue'
import PlayerAudioOnlyContainer from '~/components/player/AudioOnlyContainer.vue'
import PlayerWebcastAudioContainer from '~/components/player/WebcastAudioContainer.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import PlayerSubtitle from '~/components/player/Subtitle.vue'
import PlayerWaveform from '~/components/player/Waveform.vue'
import PlayerTimeIndicator from '~/components/player/TimeIndicator.vue'
import ExplicitHeightElement from '~/components/_general/ExplicitHeightElement.vue'
import PlayerSettingsButton from '~/components/player/SettingsButton.vue'
import PlayerSubtitleLabel from '~/components/player/SubtitleLabel.vue'
import PlayerControls from '~/components/player/Controls.vue'
import Poller from '~/components/_general/Poller.vue'
import LivePoller from '~/components/_general/LivePoller.vue'
import FullscreenElement from '~/components/_general/FullscreenElement.vue'
import Spinner from '~/components/_general/Spinner.vue'
import PlayerCenteredButton from '~/components/player/CenteredButton.vue'
import {
  LivePollerSermonFrequency,
  LivePollerWebcastFrequency,
} from '~/assets/ts/utils/webcasts'
import PlayerPreview from '~/components/player/Preview.vue'

declare global {
  interface VideoTrackList {
    // working
    // https://developer.apple.com/documentation/webkitjs/videotracklist
    length: number
    onaddtrack: () => void
    onchange: () => void
    onremovetrack: () => void
    selectedIndex: number // https://developer.apple.com/documentation/webkitjs/videotracklist/1633261-selectedindex
    getTrackById: (id: string) => string // https://developer.apple.com/documentation/webkitjs/videotracklist/1633879-gettrackbyid
  }
  interface HTMLVideoElement {
    webkitClosedCaptionsVisible: boolean | undefined // https://developer.apple.com/documentation/webkitjs/htmlmediaelement/1633311-webkithasclosedcaptions
    webkitHasClosedCaptions: boolean | undefined // https://developer.apple.com/documentation/webkitjs/htmlmediaelement/1633311-webkithasclosedcaptions
    videoTracks: VideoTrackList | undefined // https://developer.apple.com/documentation/webkitjs/htmlmediaelement/1632338-videotracks
  }
}

export default Vue.extend({
  name: 'PlayerElement',
  components: {
    PlayerPreview,
    PlayerCenteredButton,
    Spinner,
    FullscreenElement,
    LivePoller,
    Poller,
    PlayerControls,
    PlayerSubtitleLabel,
    PlayerSettingsButton,
    ExplicitHeightElement,
    PlayerTimeIndicator,
    PlayerWaveform,
    PlayerSubtitle,
    SaIcon,
    PlayerWebcastAudioContainer,
    PlayerAudioOnlyContainer,
    PlayerMetadata,
    PlayerWebcastNotification,
    PlayerEmbedDetails,
    PlayerAutoResumeContainer,
    PlayerAutoplayNextContainer,
    PlayerShortcutModal,
  },
  props: {
    whiteWaveform: {
      type: Boolean,
    },
    transparentBg: {
      type: Boolean,
    },
    fillVideo: {
      type: Boolean,
    },
    notifications: {
      type: Boolean,
      default: true,
    },
    mediaType: {
      type: Number,
      default: PlayerMediaType.Audio,
    },
    allowTypeSwitching: {
      type: Boolean,
      default: true,
    },
    allowAudioSettings: {
      type: Boolean,
    },
    allowVideoSettings: {
      type: Boolean,
      default: true,
    },
    allowTranscriptButton: {
      type: Boolean,
    },
    transcript: {
      type: Boolean,
    },
    sermon: {
      type: Object as PropType<Sermon>,
      default: undefined,
    },
    webcast: {
      type: Object as PropType<Webcast>,
      default: undefined,
    },
    startTime: {
      type: Number,
      default: 0,
    },
    resumeTime: {
      type: Number,
      default: 0,
    },
    audioStreamUrl: {
      type: String,
      default: '',
    },
    /** Does not allow seeking or scrubbing. */
    liveOnly: {
      type: Boolean,
    },
    metadata: {
      type: Boolean,
    },
    metadataDisplay: {
      type: Number as PropType<SermonMetadataDisplay>,
      default: SermonMetadataDisplay.Minimal,
    },
    globalShortcuts: {
      type: Boolean,
    },
    disableShortcuts: {
      type: Boolean,
    },
    loop: {
      type: Boolean,
    },
    popout: {
      type: Boolean,
    },
    embed: {
      type: Boolean,
    },
    /** Uses site theme for shortcut modal */
    useTheme: {
      type: Boolean,
      default: true,
    },
    /** Autoplay refers to the video playing as soon as it is loaded. For autoplaying/loading the next item in a list once the previous one has completed see autoplayList. */
    autoplay: {
      type: Boolean,
    },
    /** autoplayNextSermon refers to playing/loading the next item in a list once the previous one has completed. */
    autoplayNextSermon: {
      type: Sermon,
      default: undefined,
    },
    autoplayNextCategoryTitle: {
      type: String,
      default: '',
    },
    autoplayNextDelay: {
      type: Number,
      default: 15, // seconds
    },
    coloredControls: {
      type: Boolean,
    },
    topOffset: {
      type: String,
      default: '',
    },
    hue: {
      type: Number,
      default: 205,
      validator: (value: number) => {
        return value >= 0 && value <= 360
      },
    },
    saturation: {
      type: Number,
      default: 100,
      validator: (value: number) => {
        return value >= 0 && value <= 100
      },
    },
    dark: {
      type: Boolean,
      default: undefined,
    },
    allowRepeat: {
      type: Boolean,
    },
    autoplayMuted: {
      type: Boolean,
    },
    /**  class(es) that include "max-h" */
    settingsMaxHeight: {
      type: String,
      default: 'max-h-[calc(100vh-4.375rem)]',
      validator(value: string) {
        return value.includes('max-h') || value === ''
      },
    },
    soloUrls: {
      type: Boolean,
    },
    largeAudio: {
      type: Boolean,
    },
  },
  data() {
    const muted = this.autoplayMuted || this.$store.getters['player/muted']
    return {
      audioMedia: undefined as HTMLAudioElement | undefined,
      videoMedia: undefined as HTMLVideoElement | undefined,
      status: PlayerStatus.Static as PlayerStatus,
      durationUpdateTimestamp: 0,
      mediaDuration: 1,
      time: 0,
      now: 0,
      checkResume: false,
      resumeSet: true,
      resume: true,
      hover: false,
      buffered: 0,
      startingPercentage: 0,
      inFocus: false,
      rendered: false,
      playOnLoad: false,
      settingsOpen: false,
      settingsInitialized: false,
      transitioning: false,
      qualityPicker: false,
      subtitlePicker: false,
      autoResumePicker: false,
      isFullscreen: false,
      toggleFullscreenTime: 0,
      hoverTimeout: undefined as number | undefined,
      autoplayNextTimeout: undefined as number | undefined,
      autoplayNextStartTime: 0,
      subtitles: {} as Record<string, Subtitle>,
      subtitleTracks: [] as SubtitleTrack[],
      hls: undefined as Hls | undefined,
      qualityLevels: [] as Quality[],
      width: 1920,
      height: 1080,
      currentQuality: -1,
      videoInitialized: false,
      updatePlayHistoryTimeout: 0,
      scrubHistoryUpdating: false,
      repeatPlays: 1,
      repeatTotal: 1,
      showShortcuts: false,
      webcastIsLivePolled: undefined as boolean | undefined,
      muted,
      scrubbing: undefined as number | undefined,
    }
  },
  computed: {
    canPreview(): boolean {
      return this.showControls && !!this.sermon && !this.mobile
    },
    livePollFrequency(): number {
      return this.isWebcast
        ? LivePollerWebcastFrequency
        : LivePollerSermonFrequency
    },
    SermonMetadataDisplay() {
      return SermonMetadataDisplay
    },
    PlayerStatus() {
      return PlayerStatus
    },
    PlayerResumeState() {
      return PlayerResumeState
    },
    mouseRestThreshold(): number {
      return 3
    },
    historyUpdateFrequency(): number {
      return 60
    },
    scrubHistoryUpdateDelay(): number {
      return 3
    },
    liveSyncDuration(): number {
      return 15
    },
    watchingLiveBuffer(): number {
      return 10
    },
    currentSubtitleTrackLabel(): TranslateResult {
      if (!this.currentSubtitleTrack) return this.$t('Off')
      return this.currentSubtitleTrack?.GetAutoLabel(this) ?? ''
    },
    showPopout(): boolean {
      return this.popout && !qsBool(this, 'popout')
    },
    webcastIsLive(): boolean {
      return this.webcastIsLivePolled ?? !!this.webcast
    },
    simpleWaveform(): boolean {
      return this.customAudioStream || this.isWebcast
    },
    showPlayer(): boolean {
      return this.simpleWaveform || this.rendered
    },
    audioOnly(): boolean {
      if (!this.webcast) return false
      return this.webcast.AudioOnly
    },
    timeContainerBottomClasses(): string {
      if (!this.video || !this.showControls) return 'bottom-0'
      if (this.isWebcast) return 'bottom-2'
      return 'bottom-7'
    },
    controlsSize(): string {
      if (!this.video) return ''
      if (!this.showControls) return '13px'
      return this.isWebcast ? '3.375rem' : '4.375rem'
    },
    controlsTopStyles(): string {
      return this.controlsSize ? `top: -${this.controlsSize}` : ''
    },
    controlsBottomStyles(): string {
      return `bottom: ${this.controlsSize || '2rem'}`
    },
    goalLiveTime(): number {
      return this.duration - this.liveSyncDuration
    },
    watchingLive(): boolean {
      if (!this.isWebcast || !this.webcastIsLive) return false
      return this.time >= this.goalLiveTime - this.watchingLiveBuffer
    },
    allowSettings(): boolean {
      if (this.customAudioStream) return false
      if (this.video) return this.allowVideoSettings
      return this.allowAudioSettings
    },
    mainClass(): string {
      if (this.transparentBg) return ''
      if (this.video) return 'bg-black'
      return this.isDark ? 'bg-gray-700' : ''
    },
    audioPadding(): string {
      if (!this.audio) return ''
      if (this.embed) return 'px-3 pb-2 pt-11'
      if (this.metadata) return this.embed ? 'pt-12' : 'pt-16'
      return ''
    },
    broadcaster(): Broadcaster | undefined {
      if (this.sermon) {
        return this.sermon.broadcaster
      } else if (this.webcast) {
        return this.webcast.broadcaster
      }
      return undefined
    },
    hasSubtitles(): boolean {
      return this.subtitleTracks.length > 0
    },
    showingSubtitles(): boolean {
      return this.subtitleID !== -1
    },
    subtitleName(): string {
      return this.$store.getters['player/subtitles']
    },
    subtitleID(): number {
      const subtitleName = this.subtitleName
      return this.getSubtitleIDFromName(subtitleName)
    },
    currentSubtitleTrack(): SubtitleTrack | undefined {
      if (!this.showingSubtitles) return undefined
      if (!this.hls && !this.nativeHls) return undefined
      return this.subtitleTracks[this.subtitleID]
    },
    qualitySettingsTitle(): string {
      if (this.webcastAudio) return this.$t('Audio Only').toString()
      if (this.currentQuality !== -1) {
        return this.getQualityByHlsID(this.currentQuality).displayName
      } else {
        const auto = this.$t('Auto').toString()
        if (this.currentRealQuality === -1) {
          return auto
        } else {
          return `${auto} (${
            this.getQualityByHlsID(this.currentRealQuality).displayName
          })`
        }
      }
    },
    autoQuality(): boolean {
      if (!this.hls) return true
      if (this.webcastAudio) return false
      return this.hls.autoLevelEnabled
    },
    currentRealQuality(): number {
      if (!this.hls) return -1
      return this.autoQuality ? this.hls.currentLevel : this.hls.nextLevel
    },
    hideTime(): boolean {
      return this.video && !this.static && !this.showControls && !!this.sermon
    },
    controlsHeight(): number {
      if (this.video) return 0
      return this.customAudioStream ? 25 : this.largeAudio ? 175 : 95
    },
    isDark(): boolean {
      if (this.dark === undefined) {
        return this.$colorMode.value === 'dark'
      }
      return this.dark
    },
    darkOrVideo(): boolean {
      return this.isDark || this.video
    },
    hasVideo(): boolean {
      if (this.webcast) return true
      if (!this.sermon) return false
      return this.sermon.hasVideo
    },
    videoPosterUrl(): string {
      if (this.webcast) {
        return this.webcast.previewImageUrl ?? ''
      }
      if (!this.sermon) return ''
      return this.sermon.thumbnailURL(1920)
    },
    showControls(): boolean {
      if (!this.video || this.settingsOpen) return true
      if (this.static && this.embed) return false
      if (!this.playing) return true
      return this.hover
    },
    volume(): number {
      return this.$store.getters['player/volume']
    },
    speed(): number {
      return this.$store.getters['player/speed']
    },
    castStatus(): PlayerCastPlatform {
      return this.$store.getters['player/casting']
    },
    chromecast(): boolean {
      return this.castStatus === PlayerCastPlatform.Chromecast
    },
    coloredControlsColor(): string {
      return new HSLA({
        hue: this.hue,
        saturation: this.saturation,
        autoContrastDarkMode: this.darkOrVideo,
        autoContrast: true,
      }).css
    },
    invertedTextColor(): string {
      return this.darkOrVideo && !this.coloredControls
        ? 'text-black'
        : 'text-white'
    },
    invertedBgColor(): string {
      return this.darkOrVideo && !this.coloredControls ? 'bg-black' : 'bg-white'
    },
    nativeHls(): boolean {
      if (!this.$isClient) return false
      if (Hls.isSupported()) return false
      if (!this.videoMedia) return false
      return !!this.videoMedia.canPlayType('application/vnd.apple.mpegurl')
    },
    isWebcast(): boolean {
      return !!this.webcast
    },
    video(): boolean {
      return this.mediaType !== PlayerMediaType.Audio || this.isWebcast
    },
    audio(): boolean {
      return this.mediaType === PlayerMediaType.Audio
    },
    media(): HTMLAudioElement | HTMLVideoElement {
      if (this.video) {
        const video = this.$refs.video as HTMLVideoElement
        if (video) return video
      }
      return this.$refs.audio as HTMLAudioElement
    },
    hasMedia(): boolean {
      return !!this.audioStream
    },
    customAudioStream(): boolean {
      return !this.sermon && !this.webcast && !!this.audioStreamUrl
    },
    audioStream(): string | undefined {
      if (this.audioStreamUrl) return this.audioStreamUrl
      if (this.webcast) {
        return this.webcast.audioEventStreamUrl
      }
      if (this.sermon) {
        return this.sermon.media.highestAudio?.streamURL
      }
      return undefined
    },
    videoStream(): string {
      if (this.webcast) {
        return this.mediaType === PlayerMediaType.Video
          ? this.webcast.eventStreamUrl
          : this.webcast.audioEventStreamUrl
      }
      return this.sermon?.media.videoAdaptive?.streamURL ?? ''
    },
    webcastAudio(): boolean {
      return (
        this.isWebcast &&
        this.mediaType === PlayerMediaType.Audio &&
        !this.audioOnly
      )
    },
    mobile(): boolean {
      return this.$device.isMobileOrTablet
    },
    useShortcuts(): boolean {
      if (this.disableShortcuts) return false
      return !this.mobile && (this.globalShortcuts || this.inFocus)
    },
    hasPlayed(): boolean {
      return this.status > PlayerStatus.Loaded
    },
    static(): boolean {
      return this.status === PlayerStatus.Static
    },
    loading(): boolean {
      return this.status === PlayerStatus.Loading
    },
    hasEnded(): boolean {
      return this.status >= PlayerStatus.Ended
    },
    hasDuration(): boolean {
      return this.duration > 1
    },
    playing(): boolean {
      return this.status === PlayerStatus.Playing
    },
    container(): HTMLElement {
      return this.$refs.container as HTMLElement
    },
    debug(): boolean {
      return !!this.$route.query.debug
    },
    autoplayNextState(): AutoplayNextState {
      return this.$store.getters['player/autoplayNextState']
    },
    autoplayNextReverse(): boolean {
      return this.$store.getters['player/autoplayNextReverse']
    },
    autoplayNext(): boolean {
      return this.$store.getters['player/autoplayNext']
    },
    autoplayButtonText(): TranslateResult {
      if (!this.autoplayNext) return this.$t('Off')
      const dir = this.autoplayNextReverse
        ? this.$t('Previous')
        : this.$t('Next')
      if (!this.autoplayNextCategoryTitle) return dir
      return `${dir} (${this.autoplayNextCategoryTitle})`
    },
    resumeState(): PlayerResumeState {
      return this.$store.getters['player/resumeState']
    },
    canResume(): boolean {
      return this.resumeTime > 0 && this.resumeTime + 30 <= this.duration
    },
    settingsHome(): boolean {
      return (
        !this.autoResumePicker && !this.qualityPicker && !this.subtitlePicker
      )
    },
    loggedIn(): boolean {
      return this.$users.loggedIn
    },
    webcastPredictedDuration(): number {
      if (!this.durationUpdateTimestamp) return 0
      return (this.now - this.durationUpdateTimestamp) / 1000
    },
    duration(): number {
      const duration = this.mediaDuration
      if (!this.isWebcast) return duration
      return duration + this.webcastPredictedDuration
    },
  },
  watch: {
    webcast: {
      handler(oldValue: Webcast, newValue: Webcast) {
        if (oldValue.id === newValue.id) return
        this.sourceChange()
        if (this.autoplay) {
          this.$nextTick(() => {
            this.goLive()
            this.play()
          })
        }
      },
    },
    sermon: {
      handler(oldValue: Sermon, newValue: Sermon) {
        if (oldValue.id === newValue.id) return
        this.sourceChange()
        if (this.autoplay) {
          this.$nextTick(() => {
            this.play()
          })
        }
      },
    },
    autoplayNextSermon() {
      this.setAutoplayNextTimer()
    },
    volume() {
      this.updateVolume()
    },
    speed() {
      this.updateSpeed()
    },
    mediaType() {
      this.mediaTypeChanged()
    },
    audioStreamUrl() {
      this.sourceChange()
    },
  },
  mounted() {
    this.updateResumeState()
    this.time = this.resumeTime
  },
  destroyed() {
    clearTimeout(this.hoverTimeout)
    clearTimeout(this.updatePlayHistoryTimeout)
    this.clearAutoplayTimeout()
    this.hls?.destroy()
  },
  methods: {
    toggleCaptions() {
      this.setSubtitles(
        this.showingSubtitles ? undefined : this.subtitleTracks[0].label
      )
    },
    toggleTranscript(closeSettings = false) {
      if (closeSettings) {
        this.toggleSettings(false)
      }
      this.$emit('showTranscript', !this.transcript)
    },
    changeMediaType(mediaType: PlayerMediaType) {
      this.$emit('changeMediaType', mediaType)
    },
    setWebcastAudio() {
      this.changeMediaType(PlayerMediaType.Audio)
    },
    webcastStatusChanged(live: boolean) {
      const initialized = this.webcastIsLivePolled !== undefined
      const wasLive = !!this.webcastIsLivePolled
      this.webcastIsLivePolled = live
      if (!initialized || (live && wasLive)) return
      if (!live) {
        this.updateMediaDuration()
        return
      }
      this.hls?.loadSource(this.videoStream)
      if (this.playing || (this.hasEnded && this.hasPlayed)) {
        this.play()
      }
    },
    webcastMediaTypeChanged() {
      this.resetPlayer(true)
      this.toggleSettings(false)
    },
    mediaTypeChanged() {
      this.settingsOpen = false
      if (this.isWebcast) {
        this.webcastMediaTypeChanged()
        return
      }
      if (this.playing) {
        if (this.video && this.audioMedia) {
          this.audioMedia.pause()
        } else if (this.videoMedia) {
          this.videoMedia.pause()
        }
        this.media.currentTime = this.time
        this.play()
      } else if (this.hasPlayed) {
        if (this.video && !this.videoInitialized) {
          this.setStatus(PlayerStatus.Static)
        }
      }
    },
    updateResumeState() {
      if (!this.loggedIn) return
      this.resume = this.resumeState !== PlayerResumeState.Restart
      this.resumeSet = this.resumeState !== PlayerResumeState.Ask
    },
    clearAutoplayTimeout() {
      clearTimeout(this.autoplayNextTimeout)
    },
    setAutoplayNextTimer() {
      clearTimeout(this.autoplayNextTimeout)
      if (!this.autoplayNextSermon) return
      this.autoplayNextStartTime = new Date().getTime()
      this.autoplayNextTimeout = window.setTimeout(() => {
        if (!this.autoplayNext) return
        this.setStatus(PlayerStatus.FetchingNext)
      }, this.autoplayNextDelay * 1000)
    },
    skipAutoplayDelay() {
      this.clearAutoplayTimeout()
      this.setStatus(PlayerStatus.FetchingNext)
    },
    cancelAutoplayNext() {
      this.setAutoplayNext(AutoplayNextState.Off)
      this.clearAutoplayTimeout()
    },
    cycleAutoplayNext() {
      if (this.autoplayNextState === AutoplayNextState.Forward) {
        this.setAutoplayNext(AutoplayNextState.Reverse)
      } else if (this.autoplayNextState === AutoplayNextState.Reverse) {
        this.setAutoplayNext(AutoplayNextState.Off)
      } else {
        this.setAutoplayNext(AutoplayNextState.Forward)
      }
    },
    setAutoplayNext(state: AutoplayNextState) {
      this.$store.commit('player/SET_PLAYER_AUTOPLAY_NEXT_STATE', state)
    },
    initialSetup() {
      this.sourceChange()
      this.updatePageSize({
        width: this.container ? this.container.clientWidth : 0,
        height: this.container ? this.container.clientHeight : 0,
      })
    },
    sourceChange() {
      this.audioMedia = this.$refs.audio as HTMLAudioElement | undefined
      this.videoMedia = this.$refs.video as HTMLVideoElement | undefined
      this.resetPlayer()
      if (this.sermon && this.sermon.duration) {
        this.mediaDuration = this.sermon.duration
      }
      if (this.autoplay) {
        if (this.video) {
          this.playOnLoad = true
          this.setupHls()
        } else {
          this.play()
        }
      }
      if (this.allowRepeat) {
        this.repeatTotal = 1
        this.repeatPlays = 1
      }
    },
    resetPlayer(webcastMediaType = false) {
      clearTimeout(this.autoplayNextTimeout)
      if (this.status === PlayerStatus.Static) return
      this.status = PlayerStatus.Static
      this.mediaDuration = 1
      this.time = 0
      this.buffered = 0
      this.startingPercentage = 0
      this.hover = false
      this.subtitles = {}
      this.subtitleTracks = []
      this.currentQuality = -1
      this.videoInitialized = false

      if (!webcastMediaType) {
        this.qualityLevels = []
      }

      if (this.hls) {
        this.hls.stopLoad()
        this.hls.destroy()
      }

      if (this.autoplay || this.autoplayNext) {
        this.$nextTick(() => {
          this.play()
        })
      }
    },
    updatePageSize({ width, height }: Record<'width' | 'height', number>) {
      this.width = width
      this.height = height
    },
    backEvent() {
      if (this.settingsOpen) {
        this.toggleSettings(false)
      }
      this.$modal.hideAll()
    },
    settingsClickaway() {
      if (!this.settingsOpen) return
      this.toggleSettings(false)
    },
    toggleSettings(open: boolean) {
      this.settingsOpen = open
      if (open) {
        this.subtitlePicker = false
        this.autoResumePicker = false
        this.qualityPicker = false
      }
    },
    async settingsHeightUpdated() {
      if (!this.settingsOpen || this.settingsInitialized) return
      await waitOneFrame()
      this.settingsInitialized = true
    },
    subtitleIsAuto(langCode2: string): boolean {
      if (!this.sermon) return false
      const caption = this.sermon.captionByLangCode2(langCode2)
      return !!caption?.autoGenerated
    },
    setupHls() {
      if (this.nativeHls) {
        this.setupNativeHls()
        return
      }
      const hls = new Hls({
        debug: this.debug,
        liveSyncDuration: this.liveSyncDuration, // default undefined
        maxBufferLength: 60, // default 30
        manifestLoadingTimeOut: 15000, // default 10000
        renderTextTracksNatively: false, // https://github.com/video-dev/hls.js/blob/master/docs/API.md#rendertexttracksnatively
      })

      hls.loadSource(this.videoStream)
      hls.attachMedia(this.videoMedia as HTMLMediaElement)

      hls.on(Hls.Events.ERROR, (event, data) => {
        console.warn(event, data)
        if (data.fatal) {
          // try to recover network error
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // Handle network-related errors
              this.hls?.startLoad()
              break
            case Hls.ErrorTypes.MEDIA_ERROR:
              // Handle media-related errors
              this.hls?.recoverMediaError()
              this.pause()
              break
            default:
              // Handle other fatal errors
              this.hls?.stopLoad()
              this.hls?.destroy()
              this.setupHls()
              break
          }
        }
      })

      hls.on(
        Hls.Events.NON_NATIVE_TEXT_TRACKS_FOUND,
        (_event: string, data: NonNativeTextTracksData) => {
          this.subtitleTracks = []
          for (let i = 0; i < data.tracks.length; i++) {
            const track = data.tracks[i]
            const language = track.subtitleTrack?.lang || ''
            const auto = this.subtitleIsAuto(language)
            const subTrack = new SubtitleTrack(i, language, track.label, auto)
            this.subtitleTracks.push(subTrack)
          }
        }
      )

      // https://github.com/video-dev/hls.js/blob/master/docs/API.md#runtime-events
      hls.on(Hls.Events.CUES_PARSED, (_event: string, data: CuesParsedData) => {
        if (this.showingSubtitles) hls.subtitleTrack = this.subtitleID
        let trackID = 0
        if (data.track !== 'default') {
          trackID = parseInt(data.track.replace('subtitles', ''))
        }
        const track = this.subtitleTracks[trackID]
        data.cues.forEach((cue: VTTCue) => {
          this.addSubtitle(track, cue)
        })
      })

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (this.qualityLevels.length && this.webcastAudio) {
          this.videoInitialized = true
          return
        }
        const levels = [] as Quality[]
        const heights = [] as number[]
        const duplicates = [] as number[]
        for (let i = 0; i < hls.levels.length; i++) {
          const h = hls.levels[i].height
          if (heights.includes(h)) {
            duplicates.push(h)
          }
          heights.push(h)
        }
        for (let i = 0; i < hls.levels.length; i++) {
          const l = hls.levels[i]
          const showBitrate = duplicates.includes(l.height)
          levels.push(
            new Quality({
              id: i,
              height: l.height,
              bitrate: l.bitrate,
              showBitrate,
            })
          )
        }

        this.qualityLevels = [...levels].sort((a, b) =>
          a.height > b.height ? -1 : 1
        )
        this.setDefaultQuality()
        this.videoInitialized = true
      })

      this.hls = hls
    },
    getDefaultQuality() {
      const storedHeight = this.$store.getters['player/quality']
      if (
        !this.webcastAudio &&
        this.qualityLevels.length &&
        storedHeight !== -1
      ) {
        for (let i = 0; i < this.qualityLevels.length; i++) {
          const q = this.getQualityByHlsID(i)
          if (q.height === storedHeight) {
            return q.id
          }
        }
      }
      return -1
    },
    setDefaultQuality() {
      this.currentQuality = this.getDefaultQuality()
      if (this.currentRealQuality !== this.currentQuality) {
        this.setQuality(this.currentQuality)
      }
    },
    setupNativeHls() {
      this.mediaPlay()
      this.setupNativeSubtitles()
      this.setupNativeQualities()
      this.videoInitialized = true
    },
    setupNativeQualities() {
      // currently we don't do anything for native qualities
    },
    mediaPlay() {
      try {
        this.media.play().catch((error) => {
          console.warn(error)
        })
      } catch (e) {
        console.warn(e)
      }
    },
    getQualityByHlsID(hlsQuality: number): Quality {
      for (let i = 0; i < this.qualityLevels.length; i++) {
        const q = this.qualityLevels[i]
        if (q.id === hlsQuality) return q
      }
      return this.qualityLevels[0]
    },
    setQuality(hlsQuality: number) {
      if (!this.hls) return

      if (this.webcastAudio) {
        this.changeMediaType(PlayerMediaType.Video)
      }
      const currentHeight = this.getQualityByHlsID(hlsQuality)?.height
      const newHeight = this.getQualityByHlsID(this.hls.currentLevel)?.height

      if (currentHeight === 1080 || newHeight === 1080) {
        this.changeCodec()
      }

      this.currentQuality = hlsQuality
      this.hls.currentLevel = hlsQuality

      const auto = hlsQuality === -1
      this.$store.commit(
        'player/SET_PLAYER_QUALITY',
        auto ? -1 : this.getQualityByHlsID(hlsQuality)?.height
      )
      this.toggleSettings(false)
    },
    changeCodec() {
      this.hls?.recoverMediaError()
      this.hls?.swapAudioCodec()
      this.play()
    },
    addSubtitle(track: SubtitleTrack, cue: VTTCue) {
      const subtitle = new Subtitle(
        cue.id,
        track,
        cue.text,
        cue.startTime,
        cue.endTime
      )
      if (this.subtitles[subtitle.key]) return
      this.subtitles[subtitle.key] = subtitle
    },
    setupNativeSubtitles() {
      if (!this.videoMedia) return
      this.videoMedia.textTracks.onaddtrack = (ev: TrackEvent) => {
        const track = ev.track
        if (!track) return
        if (track.label === '') return
        const id = this.subtitleTracks.length
        const language = track.language
        const auto = this.subtitleIsAuto(language)
        const subtitleTrack = new SubtitleTrack(id, language, track.label, auto)
        track.mode = this.subtitleName === track.label ? 'hidden' : 'disabled' // options: disabled, hidden, showing
        this.subtitleTracks.push(subtitleTrack)
        track.oncuechange = () => {
          const cues = track.cues
          if (!cues) return
          for (let i = 0; i < cues.length; i++) {
            const cue = cues[i] as VTTCue
            this.addSubtitle(subtitleTrack, cue)
          }
        }
      }
    },
    getSubtitleIDFromName(name: string | undefined) {
      const track = this.subtitleTracks.filter((t) => t.label === name)
      return track.length ? track[0].id : -1
    },
    setSubtitles(name: string | undefined) {
      this.$store.commit('player/SET_PLAYER_SUBTITLES', name)
      if (this.hls) {
        this.hls.subtitleTrack = this.getSubtitleIDFromName(name)
      } else {
        this.updateNativeSubtitlesForFullscreen()
      }
    },
    updateNativeSubtitlesForFullscreen(fullscreen = false) {
      if (!this.nativeHls || !this.videoMedia) return
      const tracks = this.videoMedia.textTracks
      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i]
        const activeMode = fullscreen ? 'showing' : 'hidden' // options: disabled, hidden, showing
        track.mode = this.subtitleName === track.label ? activeMode : 'disabled' // options: disabled, hidden, showing
      }
    },
    toggleFullscreen() {
      this.toggleFullscreenTime = new Date().getTime()
    },
    setFullscreenIos(exitFullscreen = false) {
      const playing = this.playing
      if (playing) this.pause()
      if (!exitFullscreen) {
        this.media.removeAttribute('playsinline')
        this.media.addEventListener('webkitendfullscreen', () => {
          this.setFullscreenIos(true)
        })
      } else {
        this.media.setAttribute('playsinline', '')
        this.media.removeAttribute('controls')
      }
      this.updateNativeSubtitlesForFullscreen(!exitFullscreen)
      if (!playing) return
      if (exitFullscreen) {
        // we have to wait for the de-fullscreen animation to end
        setTimeout(() => {
          this.play()
        }, 400)
      } else {
        this.play()
      }
    },
    updateIsFullscreen(fullscreen: boolean) {
      this.isFullscreen = fullscreen
      this.temporarilyShowControls()
    },
    // We want to show the controls temporarily when shortcuts are pressed and fullscreen is toggled
    temporarilyShowControls() {
      // we may want something more elegant than this at some point, but the hover poller works the exact same way so ¯\_(ツ)_/¯
      this.setHover(true)
    },
    mouseMove() {
      if (this.mobile) return
      this.setHover(true)
    },
    mouseLeave() {
      if (this.mobile) return
      this.setHover(false)
    },
    setHover(hover: boolean) {
      clearTimeout(this.hoverTimeout)
      this.hover = hover
      if (hover) {
        this.hoverTimeout = window.setTimeout(() => {
          this.hover = false
        }, this.mouseRestThreshold * 1000)
      }
    },
    openShortcuts() {
      this.showShortcuts = true
      this.toggleSettings(false)
    },
    waveformRendered() {
      this.$nextTick(() => {
        this.rendered = true
        this.$emit('rendered')
      })
    },
    ended() {
      this.setStatus(PlayerStatus.Ended)
      this.updatePlayHistory(true)
      if (this.repeatPlays < this.repeatTotal) {
        this.repeatPlays++
        this.play()
      } else {
        this.repeatPlays = 1
      }
    },
    percentage(number: number): number {
      return clamp((number / this.duration) * 100, 0, 100)
    },
    goToPercentage(scrubPosition: number) {
      if (!this.hasDuration) {
        this.startingPercentage = scrubPosition
      } else {
        this.media.currentTime = scrubPosition * this.duration
      }
      this.updatePlayHistoryAfterDelay()
      this.update()
      // re-enable this if we want to force the player to play when scrubbing
      // if (!this.playing) {
      //   this.play()
      // }
    },
    updateNowTimer() {
      if (this.hasEnded) return
      this.now = new Date().getTime()
    },
    update() {
      if (this.status <= PlayerStatus.Loaded) return

      // There was once a check around these 2 lines for if (this.media.currentTime >= 1)
      // I'm not sure why it was here, and it seemed silly so I removed it
      this.time = Math.min(this.media.currentTime, this.duration)
      this.$emit('timeupdate', this.time)

      const buffers = this.media.buffered
      if (!buffers.length) return
      this.buffered = buffers.end(buffers.length - 1)
    },
    pause() {
      this.updatePlayHistoryAfterDelay()
      if (this.chromecast) return
      this.media.pause()
    },
    play() {
      if ((!this.resumeSet || this.checkResume) && this.canResume) {
        this.checkResume = true
        return
      }
      this.resumeSet = true
      if (this.chromecast) return
      if (this.static || (this.video && !this.videoInitialized)) {
        this.playOnLoad = true
        if (!this.video) {
          this.media.load()
        } else {
          this.setupHls()
        }
        this.setStatus(PlayerStatus.Loading)
      } else {
        this.mediaPlay()
      }
    },
    largePlayToggle() {
      if (this.mobile && this.video && !this.showControls && this.hasPlayed) {
        this.temporarilyShowControls()
        return
      }
      this.playToggle()
    },
    playToggle() {
      if (this.status === PlayerStatus.Playing) {
        this.pause()
      } else {
        this.play()
      }
    },
    goLive() {
      if (!this.webcast) return
      if (this.watchingLive) return
      this.media.currentTime = this.goalLiveTime
    },
    updateMediaDuration() {
      this.now = new Date().getTime()
      this.durationUpdateTimestamp = this.now
      const mediaDuration = this.media.duration
      if (!mediaDuration || mediaDuration === Infinity) return
      this.mediaDuration = mediaDuration
    },
    loaded() {
      this.updateMediaDuration()
      this.updateSpeed()
      this.updateVolume()
      this.updateMute()
      this.setStatus(PlayerStatus.Loaded)
      if (this.playOnLoad) {
        this.goLive()
        this.play()
        this.playOnLoad = false
      }
      if (!this.resume) return
      if (this.startTime) {
        this.media.currentTime = this.startTime
      } else if (this.startingPercentage) {
        this.media.currentTime = this.startingPercentage * this.duration
      } else if (this.webcast && !this.time) {
        this.goLive()
      } else if (this.resume) {
        this.media.currentTime = !this.canResume ? this.time : this.resumeTime
      }
    },
    updatePlayHistory(reset = false) {
      if (!this.loggedIn) return
      if (!this.sermon) return
      const time = reset ? 0 : this.time
      if (!time && !reset) return
      this.$apiClient.setSermonPlayHistory(this.sermon.id, time)
    },
    updatePlayHistoryAfterDelay() {
      clearTimeout(this.updatePlayHistoryTimeout)
      this.scrubHistoryUpdating = true
      this.updatePlayHistoryTimeout = window.setTimeout(() => {
        this.updatePlayHistory()
        this.scrubHistoryUpdating = false
      }, this.scrubHistoryUpdateDelay * 1000)
    },
    setStatus(status: PlayerStatus) {
      this.status = status
      this.$emit('statusUpdate', status)
    },
    setTime(seconds: number) {
      this.temporarilyShowControls()
      this.media.currentTime = seconds
      this.time = seconds
      this.updatePlayHistoryAfterDelay()
    },
    skip(seconds: number) {
      const time = clamp(this.media.currentTime + seconds, 0, this.duration)
      this.setTime(time)
    },
    updateSpeed() {
      if (this.webcast) {
        this.media.playbackRate = 1
        return
      }
      this.temporarilyShowControls()
      this.media.playbackRate = this.$store.getters['player/speed']
    },
    updateVolume() {
      this.temporarilyShowControls()
      this.media.volume = this.$store.getters['player/volume'] / 100
    },
    updateMute(mute: boolean | undefined = undefined) {
      this.temporarilyShowControls()
      if (mute !== undefined) {
        this.muted = mute
      }
      this.media.muted = this.muted
    },
    setResumeState(state: PlayerResumeState) {
      this.$store.commit('player/SET_RESUME_STATE', state)
      this.updateResumeState()
    },
    autoResume(resume: boolean) {
      this.resume = resume
      this.resumeSet = true
      this.checkResume = false
      this.time = this.resume ? this.resumeTime : 0
      this.play()
    },
    addRepeat() {
      if (this.repeatTotal < 9) {
        this.repeatTotal++
      } else {
        this.repeatTotal = this.repeatPlays
      }
    },
  },
})
