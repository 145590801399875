import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'
import { Users } from '~/models/users/users'
import { GetBroadcasterSignIn } from '~/assets/ts/utils/broadcaster'
import { broadcasterSignInUrl } from '~/assets/ts/utils/urls'
import { DashboardQs, SelectBroadcaster } from '~/assets/ts/utils/dashboard'

declare module 'vue/types/vue' {
  interface Vue {
    $users: Users
  }
}
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $users: Users
  }
  interface Context {
    $users: Users
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $users: Users
  }
}

export default (context: Context, inject: Inject) => {
  const users = new Users({
    $context: context,
  })
  inject('users', users)

  const app = context.app
  const oldLogin = app.$auth.login.bind(app.$auth)
  app.$auth.login = async (...args) => {
    await oldLogin(...args)
    const query = context.route.query
    if (!query.next || !app.router) return

    const remaining = { ...query } as Record<string, any>
    delete remaining.next
    let next = query.next as string | string[]
    if (typeof next !== 'string') {
      remaining.next = next[1]
      next = next[0]
    }
    if (next === broadcasterSignInUrl) {
      const signIn = GetBroadcasterSignIn(context)
      if (signIn.external) {
        SelectBroadcaster(
          signIn.broadcasterID ?? '',
          DashboardQs(context) ?? '/'
        )
      } else {
        await app.router.push(signIn.url)
      }
    } else {
      await app.router.push(
        `${next}?${new URLSearchParams(remaining).toString()}`
      )
    }
  }
}
