import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'
import { Collection, DisplayCollection } from '~/models/collection'

export async function getCollection(
  this: ApiClient,
  collectionSlug: string
): Promise<Collection> {
  const { data } = await this.$axios.get(`node/collections/${collectionSlug}`)
  return data
}

export async function getCollectionByHashtag(
  this: ApiClient,
  hashtag: string
): Promise<ApiPaginationResult<Collection>> {
  const { data } = await this.$axios.get(`node/collections/hashtag/${hashtag}`)
  return data
}

export async function getMenuCollections(
  this: ApiClient
): Promise<ApiPaginationResult<DisplayCollection>> {
  const { data } = await this.$axios.get(`node/collections?menu=true`)
  return data
}
