
import Vue, { PropType } from 'vue'
import SermonElement from '~/components/_general/SermonElement.vue'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import SiteTitledSection from '~/components/site/TitledSection.vue'
import SeriesElement from '~/components/_general/SeriesElement.vue'
import { Series } from '~/models/series'
import { SeriesDisplay, SermonMetadataExtendedInfo } from '~/assets/ts/enums'
import { SermonApi } from '~/apiclient/apisermons'
import { Sermon } from '~/models/sermon'

export default Vue.extend({
  name: 'SiteSeriesMoreIn',
  components: {
    SiteTitledSection,
    SermonElement,
    HorizontalRule,
    SeriesElement,
  },
  props: {
    series: {
      type: undefined as PropType<Series> | undefined,
      default: undefined,
    },
    sermonID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      prevSermonPojo: undefined as SermonApi | undefined,
      nextSermonPojo: undefined as SermonApi | undefined,
      loadingSermons: true,
    }
  },
  computed: {
    nextSermon(): Sermon | undefined {
      if (!this.nextSermonPojo) return undefined
      return new Sermon(this.nextSermonPojo)
    },
    prevSermon(): Sermon | undefined {
      if (!this.prevSermonPojo) return undefined
      return new Sermon(this.prevSermonPojo)
    },
    broadcasterID(): string | undefined {
      if (!this.series) return undefined
      return this.series.broadcasterID
    },
    SeriesDisplay() {
      return SeriesDisplay
    },
    SermonMetadataExtendedInfo() {
      return SermonMetadataExtendedInfo
    },
  },
  watch: {
    sermonID() {
      this.getMoreFromSeries()
    },
  },
  async mounted() {
    await this.getMoreFromSeries()
  },
  methods: {
    async getMoreFromSeries() {
      if (!this.series) return
      let results = (
        await this.$apiClient.getFilteredSermonList({
          series: this.series.title,
          pageSize: 1,
          broadcasterID: this.broadcasterID,
          sermonsBeforeSermon: this.sermonID,
        })
      ).results

      if (results.length > 0) {
        this.prevSermonPojo = results[0]
        this.$emit('previous', this.prevSermonPojo)
      }

      results = (
        await this.$apiClient.getFilteredSermonList({
          series: this.series.title,
          pageSize: 1,
          broadcasterID: this.broadcasterID,
          sermonsAfterSermon: this.sermonID,
        })
      ).results

      if (results.length > 0) {
        this.nextSermonPojo = results[0]
        this.$emit('next', this.nextSermonPojo)
      }

      this.loadingSermons = false
    },
  },
})
