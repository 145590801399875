import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { logError } from '~/assets/ts/utils/misc'
import { SaStatsResults } from '~/apiclient/apimisc'

export const state = () => ({
  siteStats: {
    english: '',
    seconds: 0,
    sermonCount: 2100000,
  } as SaStatsResults,
})

export type LocalState = ReturnType<typeof state>

export const mutations: MutationTree<LocalState> = {
  SET_SITE_STATS: (state, data: SaStatsResults) => {
    state.siteStats = data
  },
}

export const getters: GetterTree<LocalState, LocalState> = {
  stats: (state) => state.siteStats,
}

export const actions: ActionTree<LocalState, LocalState> = {
  async getSaStats({ commit }) {
    try {
      const data = await this.$apiClient.getSaStats()
      commit('SET_SITE_STATS', data as SaStatsResults)
    } catch (e) {
      logError(e)
    }
  },
}
