
import Vue, { PropType } from 'vue'
import { Sermon } from '~/models/sermon'
import {
  PlayerMediaType,
  SermonDisplay,
  SermonMetadataDisplay,
  SermonMetadataExtendedInfo,
} from '~/assets/ts/enums'
import {
  AdTrackerUrl,
  siteSermonUrl,
  siteShareUrl,
} from '~/assets/ts/utils/urls'
import Waveform from '~/components/player/Waveform.vue'
import CoreElementWrapper from '~/components/_general/CoreElementWrapper.vue'
import BroadcasterImage from '~/components/_general/BroadcasterImage.vue'
import { stripMarkdown } from '~/assets/ts/markdown/renderer'
import { sendAdClick } from '~/assets/ts/utils/misc'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import SermonThumbnail from '~/components/_general/SermonThumbnail.vue'
import SpeakerImage from '~/components/_general/SpeakerImage.vue'
import SermonMetadata from '~/components/_general/SermonMetadata.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SiteAdTrackerImage from '~/components/site/AdTrackerImage.vue'
import SaLinkOrHref from '~/components/_general/SaLinkOrHref.vue'
import { DisplayAdApi } from '~/apiclient/apiads'

export default Vue.extend({
  name: 'SermonElement',
  components: {
    SiteAdTrackerImage,
    InlineIcon,
    SaIcon,
    SermonMetadata,
    SpeakerImage,
    SermonThumbnail,
    LoadingElement,
    BroadcasterImage,
    CoreElementWrapper,
    Waveform,
  },
  props: {
    sermon: {
      type: Object as PropType<Sermon>,
      default: undefined,
    },
    display: {
      type: Number as PropType<SermonDisplay>,
      default: SermonDisplay.Standard,
    },
    maxExtendedInfo: {
      type: Number as PropType<SermonMetadataExtendedInfo>,
      default: SermonMetadataExtendedInfo.Broadcaster,
    },
    small: {
      type: Boolean,
    },
    customPlay: {
      type: Boolean,
    },
    subtitleColor: {
      type: String,
      default: 'text-gray-metadata',
    },
    listBorder: {
      type: Boolean,
    },
    showBibleText: {
      type: Boolean,
    },
    removeButtons: {
      type: Boolean,
    },
    hideDescription: {
      type: Boolean,
    },
    hideBroadcasterImage: {
      type: Boolean,
    },
    hidePlay: {
      type: Boolean,
    },
    showFeaturedAudioElements: {
      type: Boolean,
      default: true,
    },
    isFeaturedAd: {
      type: Boolean,
    },
    showDuration: {
      type: Boolean,
    },
    isInert: {
      type: Boolean,
    },
    autoplayPreview: {
      type: Boolean,
    },
    absoluteUrls: {
      type: Boolean,
    },
    hideSpeaker: {
      type: Boolean,
    },
    showLastPlayTime: {
      type: Boolean,
    },
    mobilePreview: {
      type: Boolean,
    },
  },
  computed: {
    SaLinkOrHref() {
      return SaLinkOrHref
    },
    PlayerMediaType() {
      return PlayerMediaType
    },
    waveformRendered(): boolean {
      const wave =
        this.$store.getters['sermons/waveform'](this.sermon?.id) ?? []
      return !!wave.length
    },
    video(): boolean {
      return this.isDisplay([SermonDisplay.Video, SermonDisplay.VideoWrap])
    },
    extendedVideo(): boolean {
      return this.isDisplay([SermonDisplay.ExtendedVideo])
    },
    largeFeatured(): boolean {
      return this.featured && !this.small
    },
    smallFeatured(): boolean {
      return this.featured && this.small
    },
    featuredButtonClasses(): string {
      const base =
        'text-gray-metadata button-obround button-outlined bg-none text-center inline-block whitespace-nowrap hover:!bg-gray-200 dark:hover:!bg-gray-800'
      return `${base} ${
        this.small || this.video
          ? `button-thin text-xs ${this.hasVideo ? 'flex-grow' : 'min-w-[50%]'}`
          : 'max-xs:flex-grow xs:min-w-[10rem] text-sm'
      }`
    },
    featuredButtonTextClasses(): string {
      return `text-xs${this.small || this.video ? '' : ' sm:text-sm'}`
    },
    placeholderOrMedia(): boolean {
      return !this.sermon || this.sermon.hasVideo || this.sermon.hasAudio
    },
    hasVideo(): boolean {
      return !!this.sermon?.hasVideo
    },
    wrap(): boolean {
      return (
        this.isDisplay([
          SermonDisplay.StandardWrap,
          SermonDisplay.ExtendedWrap,
          SermonDisplay.VideoWrap,
        ]) || this.featured
      )
    },
    speakerImageSize(): number {
      return this.largeFeatured ? 96 : 64
    },
    featured(): boolean {
      return this.isDisplay([SermonDisplay.Featured])
    },
    smallVideoThumbnail(): boolean {
      return (
        !this.small &&
        !this.video &&
        !this.extendedVideo &&
        !this.featured &&
        !this.twoCol &&
        !this.hidePlay
      )
    },
    twoCol(): boolean {
      return this.isDisplay([SermonDisplay.Standard2Col])
    },
    metadataDisplay(): SermonMetadataDisplay {
      switch (this.display) {
        case SermonDisplay.StandardWrap:
          return SermonMetadataDisplay.StandardWrap
        case SermonDisplay.VideoWrap:
          return SermonMetadataDisplay.VideoWrap
        case SermonDisplay.Video:
          return SermonMetadataDisplay.Video
        case SermonDisplay.Extended:
        case SermonDisplay.ExtendedVideo:
          return SermonMetadataDisplay.Extended
        case SermonDisplay.ExtendedWrap:
          return SermonMetadataDisplay.ExtendedWrap
        case SermonDisplay.Featured:
          return SermonMetadataDisplay.Featured
        case SermonDisplay.Standard:
        case SermonDisplay.Standard2Col:
        default:
          return SermonMetadataDisplay.Standard
      }
    },
    mobile() {
      if (this.mobilePreview) return false
      return this.$device.isMobile
    },
    previewSermonUrl(): string {
      if (!this.mobile && this.autoplayPreview) return ''
      return this.sermonVideoUrl
    },
    sermonUrl(): string {
      const base = siteSermonUrl(this.sermon)
      if (this.absoluteUrls) return siteShareUrl(base)
      return base
    },
    sermonAudioUrl(): string {
      const base = `${siteSermonUrl(
        this.sermon,
        PlayerMediaType.Audio
      )}?autoplay=1`
      if (this.absoluteUrls) return siteShareUrl(base)
      return base
    },
    sermonVideoUrl(): string {
      const base = `${siteSermonUrl(
        this.sermon,
        PlayerMediaType.Video
      )}?autoplay=1`
      if (this.absoluteUrls) return siteShareUrl(base)
      return base
    },
    displayTitle(): string {
      return this.sermon ? this.sermon.displayTitle : ''
    },
    fullTitle(): string {
      return this.sermon ? this.sermon.fullTitle : ''
    },
    description(): string | undefined {
      const text = this.sermon?.moreInfoText
      if (!text) return ''
      return stripMarkdown(text)
    },
    publishDate(): Date | undefined {
      return this.sermon ? this.sermon.publishDate : undefined
    },
    adTrackerClick(): string {
      return AdTrackerUrl(this.campaignID, false)
    },
    campaignID(): string {
      if (!this.isFeaturedAd) return ''
      const ad = this.$store.getters['site/featuredSermon'] as
        | DisplayAdApi
        | undefined
      return ad?.campaignID ?? ''
    },
  },
  methods: {
    isDisplay(list: SermonDisplay[]) {
      return list.includes(this.display)
    },
    openSermon(event: Event) {
      event.preventDefault()
      if (this.customPlay) {
        this.$emit('play')
        return
      }
      if (!this.sermon) return
      this.sermon.openPopup(this.sermonUrl)
    },
    sendClickTrack() {
      if (!this.isFeaturedAd || !this.campaignID) return
      sendAdClick(this.adTrackerClick)
    },
  },
})
