import { ApiClient } from '~/apiclient/apiclient'
import { BroadcasterApi } from '~/apiclient/apibroadcasters'
import { SpeakerApi } from '~/apiclient/apispeakers'
import { SermonApi } from '~/apiclient/apisermons'
import { SeriesApi } from '~/apiclient/apiseries'
import { HashtagApi } from '~/apiclient/apihashtags'

export enum SearchFor {
  Sermon = 'Sermon',
  Broadcaster = 'Broadcaster',
  Speaker = 'Speaker',
  Series = 'Series',
  Hashtag = 'Hashtag',
}

export class SearchRequestOptions {
  page = 1
  pageSize = 25
  lite = true
  liteBroadcaster = true
  query?: string
  searchFor?: SearchFor
}

export interface MultiSearchResults {
  broadcasterResults: BroadcasterApi[]
  hashtagResults: HashtagApi[]
  seriesResults: SeriesApi[]
  sermonResults: SermonApi[]
  speakerResults: SpeakerApi[]
  nodeType: 'search'
  nodeDisplayName: 'Search'
  next: string | null
}

export async function getMultiSearch(
  this: ApiClient,
  options: Partial<SearchRequestOptions>
): Promise<MultiSearchResults> {
  // Just using `options.query` in the params results in axios converting spaces to pluses. This is sufficient most of
  // the time, but for some search terms like "The Impossibility of the Christian Life, Part 4", encoding is necessary.
  // If we simply encode and then pass it in to the params like normal, then axios converts %20 into %2025 which breaks.
  // So to resolve this, we have to use the encoded value directly in the string instead of in the params.
  let search = options.query || ''
  search = encodeURIComponent(search)
  if (options.query) {
    delete options.query
  }
  const { data } = await this.$axios.get(`node/search?query=${search}`, {
    params: { ...options },
  })

  return data
}
