
import Vue, { PropType } from 'vue'
import { UserComment } from '~/models/usercomment'
import { DateFormat, SermonDisplay } from '~/assets/ts/enums'
import { Sermon } from '~/models/sermon'
import { AlertButton } from '~/models/generic/alert-button'
import { ToastApiError, ToastSuccess } from '~/assets/ts/utils/toast'
import { SiteAlertModal } from '~/assets/ts/utils/site'
import { localizeDateTime } from '~/assets/ts/utils/date'
import SiteUserCommentProfileIcon from '~/components/site/user/CommentProfileIcon.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import SermonElement from '~/components/_general/SermonElement.vue'

export default Vue.extend({
  name: 'SiteUserCommentElement',
  components: {
    SermonElement,
    SaIcon,
    LoadingElement,
    SiteUserCommentProfileIcon,
  },
  props: {
    userComment: {
      type: Object as PropType<UserComment>,
      default: undefined,
    },
    includeSermon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      deleted: false,
    }
  },
  computed: {
    sermonDisplay(): SermonDisplay {
      return SermonDisplay.Standard2Col
    },
    pending(): boolean {
      return this.userComment?.pendingReview
    },
    ownComment(): boolean {
      if (!this.$users.user) return false
      return this.$users.user.id === this.userComment?.displayUser?.id
    },
    location(): string | undefined {
      if (!this.userComment) return undefined
      return this.userComment.displayLocation
    },
    displayName(): string | undefined {
      return this.userComment?.displayName ?? ''
    },
    sermon(): Sermon | undefined {
      return this.userComment?.sermon
    },
    date(): string {
      if (!this.userComment) return ''
      return localizeDateTime(
        this.userComment.commentDateTime,
        DateFormat.ShortDate
      )
    },
  },
  methods: {
    deleteCommentPrompt() {
      SiteAlertModal(this, {
        title: this.$t('Delete Comment?'),
        text: this.$t('Are you sure you want to delete your comment {title}', {
          title: `"${this.userComment?.title}"`,
        }),
        neutral: new AlertButton({
          text: this.$t('Cancel'),
        }),
        positive: new AlertButton({
          text: this.$t('Delete'),
          event: async () => {
            await this.$apiClient
              .deleteComment(this.userComment?.id)
              .then(() => {
                ToastSuccess(this.$t('Comment successfully deleted!'))
                this.deleted = true
              })
              .catch((result) => {
                ToastApiError(result, this)
              })
          },
        }),
      })
    },
  },
})
